<template>
    <div>
        <div class="table-responsive">
            <table id="trial" class="table table-bordered custom-table-padding">
                <thead>
                    <tr>
                        <th>Product / Service Name</th>
                        <th class="text-end">Opening Stock</th>
                        <th class="text-end">Purchase/ GRN / Production</th>
                        <th class="text-end">Purchase Return</th>
                        <th class="text-end">Transfer</th>
                        <th class="text-end">Stock Adj</th>
                        <th class="text-end">Consumption</th>
                        <th class="text-end">Sales Invoice</th>
                        <th class="text-end">Sales Return</th>
                        <th class="text-end">Closing Stock</th>
                        <th v-if="stockType === 'value'" class="text-end">Sale Value</th>
                        <th v-if="stockType === 'value'" class="text-end">Profit Value</th>
                    </tr>
                </thead>

                <tbody v-if="balances.length">
                    <tr v-for="(item, i) in balances" :key="i">
                        <td width="15%">{{ item.product_name }}</td>
                        <td class="text-end">{{ commaFormat(item.opening_stock) }}</td>
                        <td class="text-end">{{ commaFormat(item.purchase) }}</td>
                        <td class="text-end">{{ commaFormat(item.purchase_return) }}</td>
                        <td class="text-end">{{ commaFormat(item.stock_transfer) }}</td>
                        <td class="text-end">{{ commaFormat(item.stock_adjustment) }}</td>
                        <td class="text-end">{{ commaFormat(item.consumption) }}</td>
                        <td class="text-end">{{ commaFormat(item.sales_invoice) }}</td>
                        <td class="text-end">{{ commaFormat(item.sales_return) }}</td>
                        <td class="text-end">{{ commaFormat(item.closing_stock) }}</td>
                        <td v-if="stockType === 'value'" class="text-end">{{ commaFormat(item.total_sale_value) }}</td>
                        <td v-if="stockType === 'value'" class="text-end">{{ commaFormat(item.total_profit_value) }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-if="!balances.length" class="mt-5 text-center">No data</p>
        </div>
    </div>
</template>

<script>
import figureFormatter from "@/services/utils/figureFormatter";
const { commaFormat } = figureFormatter();

export default {
    name: 'StockRegisterDetailedTable',
    props: {
        acName: String,
        balances: {
            default: [],
            type: Array
        },
        reportTotalRow: {
            default: {},
            type: Object
        },
        stockType: {
            type: String,
            default: 'quantity'
        }
    },
    methods: {
        commaFormat
    }
}
</script>

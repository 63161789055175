<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4 ">
            <label class="align-middle">Policy Name</label>
            <input type="text" class="form-control" v-model="formData.name">
          </div>
          <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4">
            <label class="align-middle">Status</label>
            <v-select
                placeholder="Select Policy"
                v-model="formData.status"
                :options="status"
                :reduce="name => name.value"
                label="name"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-1">
        <div class="card card-height">
          <div class="table-responsive">
            <table class="table ">
              <thead>
              <tr>
                <th>Day</th>
                <th>In Time</th>
                <th>Delay Buffer (Min)</th>
                <th>Working Hours</th>
                <th>Early Out Time</th>
                <th>Working Type</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in formData.item_details" :key="item.id">
                <td>
                  <v-select
                       :options="weekdays"
                       :reduce="name => name.value"
                        v-model="item.day"
                        label="name"
                        disabled="true"
                  />
                </td>
                <td>
                  <input
                      name="time"
                      type="time"
                      v-model="item.in_time"
                      class="form-control"
                  />
                </td>
                <td>
                  <input
                      name="delay_buffer"
                      type="number"
                      v-model="item.delay_buffer"
                      class="form-control"
                  />
                </td>
                <td>
                  <input
                      name="working_hours"
                      type="number"
                      v-model="item.working_hours"
                      class="form-control"
                  />
                </td>
                <td>
                  <input
                      name="time"
                      type="time"
                      v-model="item.early_out_time"
                      class="form-control"
                  />
                </td>
                <td>
                  <v-select placeholder="Select Work Type"
                            :options="workTypes"
                            :reduce="name => name.value"
                            v-model="item.working_type"
                            label="name"
                  />
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import handlePurchaseAndSales from "@/services/modules/purchase";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";

const {fetchAccountHead, ...rest} = handlePurchaseAndSales()
const {
  fetchTypes,
  createAttendancePolicy,
  fetchSingleAttendancePolicy,
  updateAttendancePolicy
} = handleHrPayroll()

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Attendance Policy")
let contacts = ref([])
let locations = ref([])
let accountPayable = ref([])
let attendancePolicyEditData = ref({})
const weekdays = ref([]);
const workTypes = ref([]);
const status = ref([]);

let policyData = {}
let formData = ref({
  company_id: companyId,
  name: null,
  status: 'active',
  item_details: [],
})

function navigateToListPage() {
  delete route.query.poliycId;
  router.push({name: `attendance-policy`, params: route.params, query: route.query});
}

function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

function handleSubmit(redirect) {
  policyData.company_id  = formData.value.company_id;
  policyData.status = "active";
  policyData.name = formData.value.name;

  policyData.item_details = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data = {};
      data.day = item.day;
      data.in_time = item.in_time;
      data.delay_buffer = item.delay_buffer;
      data.working_hours = item.working_hours;
      data.early_out_time = item.early_out_time;
      data.working_type = item.working_type;
      policyData.item_details.push(data);
    })
  }

  createAP(redirect);
}

function handleUpdate(redirect) {
  policyData.id = formData.value.id;
  policyData.company_id  = formData.value.company_id;
  policyData.status =  formData.value.status;
  policyData.name = formData.value.name;

  policyData.item_details = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data = {};
      data.id = item.id;
      data.day = item.day;
      data.in_time = item.in_time;
      data.delay_buffer = item.delay_buffer;
      data.working_hours = item.working_hours;
      data.early_out_time = item.early_out_time;
      data.working_type = item.working_type;
      policyData.item_details.push(data);
    })
  }

  updateAPData(redirect);
}

function createAP(redirect = true) {
  createAttendancePolicy(policyData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message)
         if (redirect) navigateToListPage()

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function updateAPData(redirect = true) {
  updateAttendancePolicy(policyData, policyData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Attendance Policy";
  formData.value.id =  attendancePolicyEditData.value.id;
  formData.value.name = attendancePolicyEditData.value.name;
  formData.value.status = attendancePolicyEditData.value.status;
  formData.value.item_details = [];

  attendancePolicyEditData.value.attendance_policy_generals.forEach((item) => {
    formData.value.item_details.push(item);
  })
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      workTypes.value = [];
      weekdays.value = [];
      status.value = [];
      return
    }

    if (res.data.weekDays && isCreate.value) {
      for (let key in res.data.weekDays) {
        formData.value.item_details.push({
          day: res.data.weekDays[key].value,
          in_time: '10:00',
          delay_buffer: '',
          working_hours: 8,
          early_out_time: '',
          working_type: res.data.weekDays[key].value === 'friday' ? 'weekend' : 'full_day'
        })
      }
    }

    if (res.data.weekDays) {
      weekdays.value = res.data.weekDays;
    }
    if (res.data.workTypes) {
      workTypes.value = res.data.workTypes;
    }
    if (res.data.status) {
      status.value = res.data.status;
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true
  if(route.query.policyId && route.query.policyId !== '' && route.query.policyId !== undefined){
    isCreate.value = false;
    const fetchPolicy = fetchSingleAttendancePolicy(route.query.policyId, getQuery());

    fetchPolicy.then(res2 => {
      attendancePolicyEditData.value = res2.data;
      setFormData();
    });
  }

  Promise.all([
    getTypes(),
  ]).finally(() => {
    loader.value = false
  })
}

onMounted(() => {
  onPageReload();
})
</script>
<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="px-2 mt-1">
      <div class="card card-height">
        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="form-label">Name</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="text"
                  placeholder="Name...."
                  v-model="formData.name"
              >
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="form-label">Short Name</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="text"
                  placeholder="Short Name...."
                  v-model="formData.short_name"
              >
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="form-label">Number Of Days</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="number"
                  placeholder="Number Of Days...."
                  v-model="formData.number_of_days"
              >
            </div>

            <div   class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Speciality</label>
              <v-select
                  placeholder="Select Policy"
                  v-model="formData.speciality"
                  :options="leaveSpeciality"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>
            <div   class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle me-2">Is Carriable: </label>
              <input type="checkbox" v-model="formData.is_carriable" class="form-check-input">
            </div>

            <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Status</label>
              <v-select
                  placeholder="Select Policy"
                  v-model="formData.status"
                  :options="status"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";

const store = useStore();
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Leave Policy")
let contacts = ref([])
let locations = ref([])
let accountPayable = ref([])
let leavePolicyEditData = ref({})
const status = ref([]);
const leaveSpeciality = ref([]);
let leavePolicyData = {}
let formData = ref({
  company_id: companyId,
  status: 'active',
  name:'',
  short_name:'',
  number_of_days:'',
  speciality:'',
  is_carriable:false,
})

function navigateToListPage() {
  delete route.query.leavePolicyId;
  router.push({name: `leave-policy`, params: route.params, query: route.query});
}

const {
  createLeavePolicy,
  fetchSingleLeavePolicy,
  updateLeavePolicy,
  fetchTypes,
} = handleHrPayroll()

function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

function handleSubmit(redirect) {
  leavePolicyData.company_id  = formData.value.company_id;
  leavePolicyData.status = "active";
  leavePolicyData.name = formData.value.name;
  leavePolicyData.short_name = formData.value.short_name;
  leavePolicyData.number_of_days = formData.value.number_of_days;
  leavePolicyData.speciality = formData.value.speciality;
  leavePolicyData.is_carriable = formData.value.is_carriable;

  createLP(redirect);
}

function handleUpdate(redirect) {
  leavePolicyData.id = formData.value.id;
  leavePolicyData.company_id  = formData.value.company_id;
  leavePolicyData.status = formData.value.status;
  leavePolicyData.name = formData.value.name;
  leavePolicyData.short_name = formData.value.short_name;
  leavePolicyData.number_of_days = formData.value.number_of_days;
  leavePolicyData.speciality = formData.value.speciality;
  leavePolicyData.is_carriable = formData.value.is_carriable;

  updateLPData(redirect);
}

function createLP(redirect = true) {
  createLeavePolicy(leavePolicyData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message)
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function updateLPData(redirect = true) {
  updateLeavePolicy(leavePolicyData, leavePolicyData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Leave Policy";
  formData.value.id =  leavePolicyEditData.value.id;
  formData.value.name = leavePolicyEditData.value.name;
  formData.value.short_name = leavePolicyEditData.value.short_name;
  formData.value.number_of_days = leavePolicyEditData.value.number_of_days;
  formData.value.speciality = leavePolicyEditData.value.speciality;
  formData.value.is_carriable = leavePolicyEditData.value.is_carriable === 1 ? true : false;
  formData.value.status = leavePolicyEditData.value.status;
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      status.value = [];
      leaveSpeciality.value = [];
      return
    }

    if (res.data && res.data.status) {
      status.value = res.data.status;
    }

    if (res.data && res.data.leaveSpeciality) {
      leaveSpeciality.value = res.data.leaveSpeciality;
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true

  Promise.all([
    getTypes(),

  ]).finally(() => {
    loader.value = false
  })

  if(route.query.leavePolicyId && route.query.leavePolicyId !== '' && route.query.leavePolicyId !== undefined){
    isCreate.value = false;
    const fetchLeavePolicy = fetchSingleLeavePolicy(route.query.leavePolicyId, getQuery());
    fetchLeavePolicy.then(res2 => {
      leavePolicyEditData.value = res2.data;
      setFormData();
    });
  }

}

onMounted(() => {
  onPageReload();
})
</script>
<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          title="Stock Adjustment List"
          @onClickAddNewButton="onClickAddNewButton"
      />
      <div class="row match-height mt-1">
        <div class="col-12 col-lg-10 col-xl-10 col-xxl-10">
          <DateQuerySetter @onClickGo="getStockAdjustments">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <label
                for="colFormLabel"
                class="col-form-label"
              >
                Adjustment Number
              </label>
              <input
                v-model="adjustmentNo"
                type="text"
                class="form-control date-mask"
                placeholder="Adjustment no"
              >
            </div>
          </DateQuerySetter>
        </div>
      </div>
    </div>

    <ListTable :adjustmentList="adjustmentList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton           from '@/components/atom/TitleButton'
import DateQuerySetter       from '@/components/atom/DateQuerySetter'
import ListTable             from '@/components/molecule/procurement/inventory/stock/adjustment/ListingTable'
import Pagination            from '@/components/atom/Pagination'
import {useRoute, useRouter}    from "vue-router";
import {inject, onMounted, ref} from "vue";
import handleStock              from "@/services/modules/stock";
import {useStore}            from "vuex";

const $router = useRouter();
const $route = useRoute();
const $store = useStore();
const offset = ref(20);
const showError = inject('showError');
const {fetchStockAdjustmentList} = handleStock();
const adjustmentList = ref([]);
const adjustmentNo = ref("");

function onClickAddNewButton() {
  let params = $route.params;
  let query = $route.query;
  $router.push({name: 'stock-adjustment-add', params: params, query: query});
}

const getQuery = () => {
    let query = '?company_id=' + $route.params.companyId
    query += '&offset=' + offset.value
    query += '&start_date=' + $route.query.start
    query += '&end_date=' + $route.query.end
    if (adjustmentNo.value) query += '&adjustment_no=' + adjustmentNo.value
    if ($route.query.page) query += '&page=' + $route.query.page
    return query
}

const getStockAdjustments = () => {
    fetchStockAdjustmentList(getQuery()).then(res => {
        if(res.date) {
            adjustmentList.value = res.date.data;
            setPagination(res.date)
        }else{
          adjustmentList.value = [];
        }
    })
    .catch( err => {
        showError(err.response.message);
    })
}

function setPagination(data) {
    if(!data.data.length) return
    $store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery  = Object.assign({}, $route.query)
    routeQuery.page = page
    await $router.push({path: $route.path, query: routeQuery})
    await getStockAdjustments();
}

onMounted(() => {
    getStockAdjustments();
})

</script>

<template>
    <div class="modal fade show modal-style" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-lg modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                    <h1 class="mb-0">{{ title }} </h1>
                    <button
                        @click="onClickClose"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">
                    <div class="row py-1 mx-auto d-flex align-items-center">
                      <div class="col-12" >
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <label class="form-label">Start Date</label>
                            <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="formData.start_date"
                            >
                          </div>

                          <div class="col-12 col-md-6">
                            <label class="form-label">End Date </label>
                            <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="formData.end_date"
                            >
                          </div>

                          <div class="col-12 col-md-6">
                            <label class="form-label">Number Of Days</label>
                            <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="number"
                                placeholder=""
                                readonly
                                v-model="formData.number_of_days"
                            >
                          </div>

                          <div class="col-12 col-md-6">
                            <label class="align-middle">Adjustment Work</label>
                            <v-select
                                placeholder="Select Adjustment Work"
                                v-model="formData.adjustment_type"
                                :options="adjustmentType"
                                :reduce="name => name.value"
                                label="name"
                            />
                          </div>

                          <div class="col-12 col-md-6">
                            <label class="form-label">Emergency Contact</label>
                            <textarea
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="text"
                                placeholder=""
                                v-model="formData.emergency_contact">
                            </textarea>
                          </div>

                          <div class="col-12 col-md-6">
                            <label class="form-label">Purpose</label>
                            <textarea
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="text"
                                placeholder=""
                                v-model="formData.purpose">
                            </textarea>
                          </div>

                          <div class="col-12 col-md-6">
                            <label class="align-middle">Task Assign to (During Leave)</label>
                            <v-select
                                placeholder="Select Employee"
                                v-model="formData.task_assigned_contact_profile_id"
                                :options="contacts"
                                label="name"
                                :reduce="name => name.id"
                            />
                          </div>

                        </div>
                      </div>

                      <div class="col-12 pt-2">
                        <button @click="handleSave" class="btn btn-primary btn-block waves-effect waves-float waves-light">
                          Submit
                        </button>
                      </div>

                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import Loader from "@/components/atom/LoaderComponent";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import {inject, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import handleContact from '@/services/modules/contact'

const props = defineProps({
    title: {
        type: String,
        default: 'Leave Application'
    },
    type: {
        type: String,
        default: 'all'
    },
    isInTime: {
        type: Boolean,
        default: true
    },
  policyBalanceId: {
      type: Number,
      default: null
    },
})

const emit = defineEmits(['getMyLeaveList']);

const {
  createMyLeave,
  fetchTypes
} = handleHrPayroll()

const { fetchContactProfiles } = handleContact()

const $store = useStore();
const $route = useRoute();
const loading = ref(false);
const loader = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const adjustmentType = ref([]);
let contacts = ref([])

let timer = null;
const myLeaveData = {}
let formData = ref({
   start_date: '',
   end_date: '',
   number_of_days: '',
   adjustment_type: '',
   leave_balance_id: '',
   purpose: '',
   task_assigned_contact_profile_id: '',
   emergency_contact: '',
   status: 'approved',
})

const handleSave = async () => {
    loading.value = true;

    myLeaveData.company_id = $route.params.companyId;
    myLeaveData.start_date = formData.value.start_date;
    myLeaveData.end_date = formData.value.end_date;
    myLeaveData.number_of_days = formData.value.number_of_days;
    myLeaveData.adjustment_type = formData.value.adjustment_type;
    myLeaveData.leave_balance_id = props.policyBalanceId;
    myLeaveData.purpose = formData.value.purpose;
    myLeaveData.task_assigned_contact_profile_id = formData.value.task_assigned_contact_profile_id;
    myLeaveData.emergency_contact = formData.value.emergency_contact;
    myLeaveData.status = formData.value.status;
    await saveMyLeaveInfo()
}

const saveMyLeaveInfo = () => {
  createMyLeave(myLeaveData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        emit('getMyLeaveList')
        onClickClose()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

const onClickClose = () => {
    $store.state.isModalOpenThree = false;
}

function getQuery() {
    return `?company_id=${$route.params.companyId}`;
}

watch(formData, (newVal, oldVal) => {
    if (!newVal.start_date || !newVal.end_date) {
        return
    }
    const startDate = new Date(newVal.start_date);
    const endDate = new Date(newVal.end_date);
    const diffTime = endDate - startDate;
    if (diffTime < 0){
      return
    }
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    formData.value.number_of_days = diffDays + 1;
}, {deep: true})

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      adjustmentType.value = [];
      return
    }

    if (res.data && res.data.leaveAdjustmentType) {
      adjustmentType.value = res.data.leaveAdjustmentType;
    }

  }).catch(err => {
    console.log(err)
  })
}

onMounted(() => {
  const query = getQuery()
  const contactQuery = query + '&type=employee'
  const contactRes   = fetchContactProfiles(contactQuery)

  Promise.all([
    getTypes(),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
  ]).finally(() => {
    loader.value = false
  })
});

</script>

<style scoped>
.modal-style {
    display: block; 
    background: #1c1d1c33
}
.roles-segment {
    margin-top: -13px;
}
.date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.highlight {
  color: black;
  font-weight: bold;
}
</style>

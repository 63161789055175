<template>
  <div>
    <div class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Supplier</label>
              <v-select
                placeholder="Select Supplier"
                v-model="formData.supplier_id"
                :options="contacts"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div class="col-sm-4">
              <label class="align-middle">PO No</label>
              <input type="text" class="form-control" :value="formData.po_number" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">PO Date</label>
              <input type="date" class="form-control date-mask" v-model="formData.po_date" :disabled="!shouldSave">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <label class="align-middle">Requisition Details:</label>
              <div class="mt-1">
                <template :key="index" v-for="(data, index) in formData.requisition_details">
                  Requisition No: <strong>{{ data.requisition_number }}</strong>, Requisition Date:
                  <strong>{{ data.requisition_date }}</strong>,
                  Requester Name: <strong>{{ data.requester_name }}</strong>, Designation: , Department: .
                  <br>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li @click="formData.has_item_detail = false" class="nav-item">
            <a :class="{'active' : !formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </ul>
      </div>

      <div class="px-3">
        <div v-if="!formData.has_item_detail">
          <template :key="index" v-for="(data, index) in accountsDetails">
            <ItemDetails
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="false"
              :isPO="false"
              :is-disabled="!shouldSave"
              :isPOCreate="isPOCreate"
            />
          </template>
        </div>

        <div v-else>
          <template :key="index" v-for="(data, index) in itemsDetails">
            <ItemDetails
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="true"
              :isPO="false"
              :is-disabled="!shouldSave"
              :isPOCreate="isPOCreate"
            />
          </template>
        </div>
      </div>

      <div class="px-2 mt-3 mb-3">
        <div class="row mt-3 px-2">
          <div class="col-md-7 row justify-content-center">
          </div>

          <div class="col-md-5">
            <div class="row mt-1">
              <div class="col-md-4 align-middle">SubTotal :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="subTotal" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">VAT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="totalVAT" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">Total :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="total" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(-) Discount / Round up Adj :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.adjustment_amount" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) Carrying Cost :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.carrying_cost" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">NET PAYABLE AMOUNT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model.trim="grandTotal" readonly>
              </div>
            </div>
          </div>

          <div v-for="(note, index) in poNotes" :key="note.vaule" class="col-12 mt-3">
            <AdmissionNote
                :note="note"
                :index="index"
            />
          </div>

          <div class="col-lg-12 col-12 mt-2">
            <div class="mb-1">
              <label class="form-label" for="note">Comments</label>
              <textarea
                v-model="comment"
                placeholder="Comments"
                class="form-control"
                rows="1"
                :readonly="viewOnly"
              />
            </div>
          </div>
        </div>

        <div class="row mb-1 d-flex justify-content-end">
          <div
            class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2"
          >
            <button :disabled="saveButtonLoader|| (formData.approved_by_self > 0)" class="btn btn-danger" @click="changeStatus('rejected')">
              Reject
            </button>

            <button
              :disabled="saveButtonLoader|| (formData.approved_by_self > 0)"
              class="btn btn-primary"
              @click="changeStatus('returned')"
            >
              Return
            </button>

            <button
              :disabled="saveButtonLoader|| (formData.approved_by_self > 0)"
              class="btn btn-success"
              @click="changeStatus((formData.status !== 'pending_approve') ? 'pending_approve' : 'approved')"
            >
              {{getConfirmButtonName()}}
            </button>
            <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            <button @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)" class="btn btn-warning">Show More</button>
          </div>
        </div>
      </div>
    </div>

    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="formData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
  </div>
</template>

<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}
</style>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import AdmissionNote from '@/components/molecule/company/hospital/note/po/Note.vue';
import {
  inject,
  ref,
  onMounted,
  computed, watch
} from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import {useStore}         from 'vuex';
import BlobImageViewer    from '@/components/atom/BlobImageViewer'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handlePurchase     from '@/services/modules/purchase'
import handleContact      from '@/services/modules/contact'
import ItemDetails        from '@/components/molecule/procurement/purchase-order/purchase-order-entry/ItemDetails.vue'
import {vatRate}          from '@/data/inventory.js'
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store       = useStore()

const {fetchProductList, fetchAccountHead, fetchCurrencyList, ...rest} = handlePurchase()
const {
  fetchPONumber,
  fetchSinglePO,
  fetchRequisitionGeneralData,
  updatePOStatus,
  fetchPONotes
} = handleRequisitions();
const {fetchContactProfiles} = handleContact()
const {fetchWorkflowFromPageId} = handleWorkflow()

const companyId   = computed(() => {
  return route.params.companyId
})
let headerTitle   = ref('Create PO')
let loader        = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let products      = ref([])
let currencies    = ref([])
let accountHeads  = ref([])
let requisition   = ref({})
let supplier      = ref(null)
let user          = ref(null)
let comment       = ref(null)
let poEditData    = ref({})
let adjustmentTotal = ref(null);
let grandTotal = ref(null);
const workflowDetails = ref({});
const viewApprovalMatrix = ref(null);
let isPOCreate    = true
let formData      = ref({
  supplier_id        : null,
  po_number          : null,
  po_date            : null,
  requisition_details: [],
  subtotal_amount    : 0.00,
  vat_amount         : 0.00,
  total_amount       : 0.00,
  has_item_detail    : true,
  adjustment_amount: 0,
  carrying_cost: 0,
  description        : '',
  item_details       : [
    {
      product_id  : null,
      quantity    : null,
      currency    : null,
      rate        : null,
      amount      : null,
      vat         : null,
      vat_amount  : null,
      total_amount: null,
      description : null,
    }
  ],
  account_details    : [
    {
      account_head_id: null,
      currency       : null,
      amount         : null,
      vat            : null,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
    }
  ],
})
let contacts                 = ref([])
let supplier_id              = ref(null)
const poNotes = ref([]);

function onPageReload() {
  loader.value         = true
  const productRes     = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  const companyQuery = `?company_id=${companyId.value}`
  const contactRes   = fetchContactProfiles(companyQuery)
  const currencyRes  = fetchCurrencyList(getQuery())
  const workflowQuery = getQuery() + `&approval_list_page_id=${route.params.pageId}`;
  const fetchNote  = fetchPONotes(getQuery())

  Promise.all([
    fetchNote.then((res) => {
      if(!res.status) return;
      poNotes.value = res.data.po_notes;
    }),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    currencyRes.then(res => {
      if (res.data) currencies.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data

      if (route.query.poId && route.query.poId !== '' && route.query.poId !== undefined) {
        prepareUpdatePageData();
      } else {
        prepareCreatePageData();
      }
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    }),
  ])
    .then(() => {
      loader.value = false;
    })
    .catch((err) => {
      loader.value = false
    })
}

function prepareUpdatePageData(){
  isPOCreate    = false;
  const fetchPO = fetchSinglePO(route.query.poId, getQuery());
  fetchPO.then(res2 => {
    poEditData.value = res2.data;
    setFormData();
    setNoteData(res2.data.notes)
  });
}

const setNoteData = (notes) => {

  for(let note of notes){
    const poNote = poNotes.value.find(item => item.value === note.type);
    if(!poNote) continue;
    poNote.id = note.id;
    poNote.isHideAddBtn = true
    poNote.selectedNotes = note.note;
  }
}

function prepareCreatePageData(){
  const selected_requisitions    = {
    selected_requisitions: route.query.selected_requisitions
  }
  // Generate PO number
  const poNumberRes = fetchPONumber(getQuery());
  poNumberRes.then(res => {
    formData.value.po_number = res;
  });
  // Fetch Requisition Generals
  const fetchRequisitionGenerals = fetchRequisitionGeneralData(selected_requisitions, getQuery());
  fetchRequisitionGenerals.then(res => {
    if (res.status) {

      let prData = res.data;
      delete formData.value.item_details;
      delete formData.value.account_details;
      formData.value.item_details        = [];
      formData.value.account_details     = [];
      formData.value.requisition_details = [];

      prData.forEach((item) => {
        formData.value.requisition_details.push({
          requisition_number: item.requisition_number,
          requisition_date  : new Date(item.date).toISOString().split('T')[0],
          requester_name    : item.user.name,
        });

        if (item.general.length > 0){
          item.general.forEach((general) => {
            if (general.procurement_type === "single_vendor"){
              general.is_product = 1;
              general.currency = 'BDT'
              formData.value.item_details.push(general);
            }
          });
        }

        if (item.account.length > 0){
          item.account.forEach((account) => {
            if (account.procurement_type === "single_vendor"){
              account.is_product = 0;
              general.currency = 'BDT'
              formData.value.account_details.push(account);
            }
          });
        }
      })
      setFormData();
    }
  })
}

const getConfirmButtonName = () => {
  if(formData.value.approved_by_self > 0) {
    return 'Already Approved';
  }

  if(formData.value.status === 'pending_approve') {
    return 'Approve';
  }

  return "Confirm"
}

function setFormData() {
  if (isPOCreate) {
    formData.value.po_date     = new Date().toISOString().split('T')[0];
  } else {
    headerTitle.value          = 'Update PO';
    formData.value.supplier_id = poEditData.value.contact_profile_id;
    formData.value.po_date     = new Date(poEditData.value.po_date).toISOString().split('T')[0];
    formData.value.po_number   = poEditData.value.po_number;
    formData.value.description = poEditData.value.description;
    formData.value.workflow_approval_logs = poEditData.value.workflow_approval_logs;
    formData.value.adjustment_amount = poEditData.value.adjustment_amount;
    formData.value.carrying_cost = poEditData.value.carrying_cost;
    formData.value.status = poEditData.value.status;

    delete formData.value.item_details;
    delete formData.value.account_details;
    formData.value.item_details        = [];
    formData.value.account_details     = [];
    formData.value.requisition_details = [];
    poEditData.value.purchase_general.forEach((item) => {
      if (item.is_product == '1') {
        formData.value.requisition_details.push({
          requisition_number: item.requisition_general.requisition_number,
          requisition_date  : new Date(item.requisition_general.requisition_master.date).toISOString().split('T')[0],
          requester_name    : item.requisition_general.requisition_master.user.name,
        });

        item.product_id = item.requisition_general.product_id;
        formData.value.item_details.push(item);
      } else {
        formData.value.requisition_details.push({
          requisition_number: item.requisition_account.requisition_number,
          requisition_date  : new Date(item.requisition_account.requisition_master.date).toISOString().split('T')[0],
          requester_name    : item.requisition_account.requisition_master.user.name,
        });

        item.account_head_id = item.requisition_account.account_head_id;
        formData.value.account_details.push(item);
      }
    })
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function onClickCloseButton() {
  delete route.query.poId;
  router.push({name: `po-list`, params: route.params, query: route.query});
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function changeStatus(status){
  if(status === 'approved' && ! confirm('Are you sure you want to approve this purchase order')){
    return;
  }
  saveButtonLoader.value = true

  let statusData = {
    'status' : status,
    'workflow_master_id' : workflowDetails.value.id,
    'comment' : comment.value,
  }
  updatePOStatus(route.query.poId, getQuery(), statusData)
    .then(res => {
      saveButtonLoader.value = false
      if(res.status) {
        showSuccess(res.message)
        navigateToListPage();
      }
      if(!res.status) {
        showError(res.message)
      }
    })
    .catch(err=> {
      saveButtonLoader.value = false;
      showError(err)
    })
}

const shouldSave = computed( () => {
  if(! formData.value.status) {
    return true;
  }

  return ['pending', 'returned'].includes(formData.value.status);
})

function navigateToListPage() {
  delete route.query.poId;
  delete route.query.selected_requisitions;
  router.push({name: `po-approval`, params: route.params, query: route.query});
}

const itemsDetails    = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})
const isItem          = computed(() => {
  return !!formData.value.has_item_detail
})
const subTotal        = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseInt(item.amount)
      }
    })
  }

  return subTotal
})
const totalVAT        = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    itemsDetails.value.map(item => {
      if (item.vat_amount) {
        vat += parseInt(item.vat_amount)
      }
    })
    return vat.toFixed(2);
  }

  formData.value.account_details.map(item => {
    if (item.vat_amount) {
      vat += parseInt(item.vat_amount)
    }
  })
  return vat.toFixed(2)
})
const total           = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

const discountAmount = computed(() => formData.value.adjustment_amount);
const carryingCost = computed(() => formData.value.carrying_cost);

watch(discountAmount, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    adjustmentTotal.value = total.value
    formData.value.adjustment_amount = 0;
    return;
  }

  if (newValue !== oldValue) {
    adjustmentTotal.value = total.value - discountAmount.value
  }
})

watch(carryingCost, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    grandTotal.value = parseFloat(adjustmentTotal.value);
    formData.value.carrying_cost = 0;
    return;
  }

  if (newValue !== oldValue) {
    grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value)).toFixed(2);
  }
})

watch(total, () => {
  adjustmentTotal.value = total.value  - discountAmount.value;
})

watch(adjustmentTotal, () => {
  grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value)).toFixed(2);
})

watch(adjustmentTotal, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    formData.value.adjustment_amount = parseFloat((total.value - newValue).toFixed(4));
  }
})

onMounted(async () => {
  onPageReload();
})
</script>

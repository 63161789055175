<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="PR Allocation"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requester</label>
            <input type="text" class="form-control" :value="user" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Requisition No</label>
            <input type="text" class="form-control" :value="formData.requisition_number" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Department</label>
            <v-select
                placeholder="Select Department"
                v-model="formData.department_id"
                :options="departments"
                label="name"
                :reduce="name => name.id"
                disabled
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requisition Date</label>
            <input type="date" class="form-control" :value="requisitionDate" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Project</label>
            <v-select
                placeholder="Select Project"
                v-model="formData.project_id"
                :options="projects"
                label="name"
                :reduce="name => name.id"
                disabled
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Business</label>
            <v-select
                placeholder="Select Business"
                v-model="formData.business_id"
                :options="businesses"
                label="name"
                :reduce="name => name.id"
                disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-6 mt-1">
            <div class="mb-1">
              <label class="form-label" for="vat_type">Procurement Type <strong style="color: red">*</strong></label>
               <v-select
                  placeholder="Select"
                  v-model="procurementType"
                  :options="procurementTypes"
                  label="text"
                  :reduce="text => text.id"
              />
            </div>
          </div>
          <div class="col-lg-4 col-6 mt-1">
            <div class="mb-1">
              <label class="form-label" for="vat_type">Purchase Location <strong style="color: red">*</strong></label>
              <v-select
                  placeholder="Select"
                  v-model="purchaseLocation"
                  :options="purchaseLocations"
                  label="text"
                  :reduce="text => text.id"
              />
            </div>
          </div>
          <div class="col-lg-4 col-6 mt-3">
            <button
                @click="updateLocationAndType()"
                class="btn btn-primary"
            >
              Go
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="px-2">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link"
             data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
        </li>
        <li class="nav-item">
          <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link"
             data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
        </li>
      </ul>
    </div>

    <div class="px-2 mt-2"
         v-show="!isItem"
    >
      <AddItemDetails
          class="mb-2"
          :products="products"
          :accountHeads="accountHeads"
          v-for="(item, index) in formData.account_details"
          :key="index"
          :index="index"
          :item="item"
          :isItem="isItem"
          :vatRate="vatRate"
          :isAllocate="true"
          :procurementTypes="procurementTypes"
          :purchaseLocations="purchaseLocations"
          @onClose="onClose"
      />
    </div>
    <div class="px-2 mt-2"
         v-show="isItem"
    >
      <AddItemDetails
          class="mb-2"
          :products="products"
          :accountHeads="accountHeads"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          :isItem="isItem"
          :vatRate="vatRate"
          :isAllocate="true"
          :procurementTypes="procurementTypes"
          :purchaseLocations="purchaseLocations"
          @onClose="onClose"
      />
    </div>

    <div class="p-1">
      <div class="row" v-if="formData.additional_images.length > 0">
        <div class="col-12">
          <div class="mb-2">
            <label for="attachment" class="form-label fw-bold">Attachment</label>
            <BlobFileViewer :deletePermission="false" :attachments="formData.additional_images" />
          </div>
        </div>
      </div>
      <div class="row mt-3 px-2">
        <div class="col-md-7 row justify-content-center"></div>
        <div class="col-md-5">
          <div class="row mt-1">
            <div class="col-md-4 align-middle">SubTotal :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="subTotal" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">VAT :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="totalVAT" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">Total :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="total" readonly>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2" id="description"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button
        v-if="requisition.pending_general_count"
        :disabled="saveButtonLoader || saveNewButtonLoader"
        @click="handleSubmit(true)"
        class="btn btn-primary"
      >
        Save
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {inject, ref, onMounted, computed, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/requisition/AddItemDetails"
import AddButton from "@/components/atom/AddButton"
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleProjects from "@/services/modules/procurement/project";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses';
import {vatRate} from '@/data/inventory.js'

const router = useRouter()
const route = useRoute()
const showError = inject('showError')
const showSuccess = inject('showSuccess')
const store = useStore()

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchProjects} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchBusinessList} = handleCBusinesses();
const {
        updatePRAllocation,
        fetchSingleRequisition
      } = handleRequisitions();

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let projects = ref([])
let businesses = ref([])
let products = ref([])
let accountHeads = ref([])
let departments = ref([])
let requisition = ref({})
let user = ref(null)
let requisitionDate = ref(null)
let procurementType = ref(null)
let purchaseLocation = ref(null)
let formData = ref({
  id:null,
  company_id:null,
  requisition_number: null,
  department_id: null,
  date: null,
  project_id: null,
  project_budget_ref: null,
  business_id: null,
  subtotal_amount: 0.00,
  vat_amount: 0.00,
  total_amount: 0.00,
  has_item_detail: true,
  description: '',
  additional_images: [],
  account_details: [
    {
      account_head_id: null,
      amount: null,
      vat: null,
      vat_amount: null,
      total_amount: null,
      description: null
    }
  ],
  item_details: [
    {
      product_id: null,
      quantity: null,
      rate: null,
      amount: null,
      vat: null,
      vat_amount: null,
      total_amount: null,
      description: null
    }
  ],
});

let procurementTypes = [
    {
      text : 'CS',
      id : 'cs',
    },
    {
      text : 'Single Vendor',
      id : 'single_vendor',
    },
    {
      text : 'Spot Purchase',
      id : 'spot_purchase',
    },
];
let purchaseLocations = [
  {
    text : 'Import',
    id : 'import',
  },
  {
    text : 'Local',
    id : 'local',
  }
];

function navigateToListPage() {
  router.push({name: `pr-allocation-list`, params: route.params, query: route.query});
}

function updateLocationAndType() {
  let itemDetails = formData.value.item_details;
  let accountDetails = formData.value.account_details;

  if (itemDetails.length > 0){
    itemDetails
        .filter(item => item.status === 'pending')
        .forEach(item => {
          item.procurement_type = procurementType.value;
          item.purchase_location = purchaseLocation.value;
        });
  }

  if (accountDetails.length > 0){
    accountDetails
        .filter(item => item.status === 'pending')
        .forEach(item => {
          item.procurement_type = procurementType.value;
          item.purchase_location = purchaseLocation.value;
        });
  }
}

function handleSubmit(redirect=false) {
  let itemDetails = formData.value.item_details;
  let accountDetails = formData.value.account_details;
  let isValidated = true;

  if (itemDetails.length > 0){
    itemDetails.forEach((item)=>{
      if (item.procurement_type === null || item.purchase_location === null){
        isValidated = false;
      }
    })
  }

  if (accountDetails.length > 0){
    accountDetails.forEach((item)=>{
      if (item.procurement_type === null || item.purchase_location === null){
        isValidated = false;
      }
    })
  }

  if (! isValidated){
    showError("Procurement Type and Purchase Location are required.");
    return;
  }

  if(redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  let copyFormData = JSON.parse(JSON.stringify(formData.value))
  copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
  if(isItem.value) {
    delete copyFormData.account_details
    copyFormData.item_details = JSON.stringify(formData.value.item_details)
  }else {
    delete copyFormData.item_details
    copyFormData.account_details = JSON.stringify(formData.value.account_details)
  }

  updatePRAllocation(copyFormData, getQuery())
      .then(res => {
        saveButtonLoader.value = false;
        saveNewButtonLoader.value = false;
        if(res.status) {
          showSuccess(res.message)
          if(redirect) navigateToListPage()
          resetForm()
        }
        if(!res.status) {
          showError(res.message)
        }
      })
      .catch(err=> {
        saveButtonLoader.value = false;
        saveNewButtonLoader.value = false;
        (err)
      })
}

function onClose(index) {
  // if(isItem) {
  //   formData.value.item_details.splice(index, 1)
  // } else {
  //   formData.value.account_details.splice(index, 1)
  // }
}

function onClickAdd() {
  if(isItem.value) {
    formData.value.item_details.push({
      product_id: null,
      quantity: null,
      rate: null,
      amount: null,
      vat: null,
      vat_amount: null,
      total_amount: null,
      description: null
    });
  } else {
    formData.value.account_details.push({
      account_head_id: null,
      amount: null,
      vat: null,
      vat_amount: null,
      total_amount: null,
      description: null
    });
  }

}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const isItem = computed(() => {
  return !!formData.value.has_item_detail
})

const subTotal = computed(() => {
  let subTotal=0
  if(formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if(!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if(item.amount) {subTotal += parseInt(item.amount)}
    })
  }

  return subTotal
})

const totalVAT = computed(() => {
  let vat=0
  if(formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if(item.vat) {
        vat += parseInt((item.vat/100) * item.quantity * item.rate)
      }
    })
  }
  if(!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if(item.vat) {
        vat += parseInt((item.vat/100) * item.amount)
      }
    })
  }

  return vat.toFixed(2)
})

const total = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

onMounted(async () => {
  const projectsRes = fetchProjects(getQuery());
  const businessRes = fetchBusinessList(getQuery());
  const productRes = fetchProductList(getQuery());
  const accountHeadRes = fetchAccountHead(getQuery());
  const departmentListRes = fetchDepartmentList(getQuery());
  const companyQuery = `?company_id=${route.params.companyId}`;
  const fetchRequisition = fetchSingleRequisition(route.params.requisitionId, companyQuery);

  await Promise.all([
    projectsRes.then(res => {
      if(res.data) projects.value = res.data;
    }),
    businessRes.then(res => {
      if(res.data) businesses.value = res.data;
    }),
    productRes.then(res=> {
      if(res.data) products.value = res.data
    }),
    accountHeadRes.then(res=> {
      if(res.data) accountHeads.value = res.data
    }),
    departmentListRes.then(res=> {
      if(res.data) departments.value = res.data
    }),

    fetchRequisition.then(res => {
      requisition.value = res.data;

      user.value = requisition.value.user.name;
      formData.value.id = requisition.value.id;
      formData.value.requisition_number = requisition.value.requisition_number;
      formData.value.department_id = requisition.value.department_id;
      formData.value.project_id = requisition.value.project_id;
      formData.value.business_id = requisition.value.business_id;
      requisitionDate.value = new Date(requisition.value.date).toISOString().split('T')[0];
      formData.value.company_id = requisition.value.company_id;
      formData.value.subtotal_amount = requisition.value.subtotal_amount;
      formData.value.vat_amount = requisition.value.vat_amount;
      formData.value.total_amount = requisition.value.total_amount;
      formData.value.description = requisition.value.description;
      formData.value.has_item_detail = requisition.value.has_item_detail;
      formData.value.item_details = requisition.value.general;
      formData.value.account_details = requisition.value.account;
      formData.value.additional_images = requisition.value.attachments;
    })
  ]);
})
</script>

<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date | Day</th>
          <th>Location</th>
          <th>In Time</th>
          <th>Out Time</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i"
            :class="getRowStatus(item.status)"
         >
          <td>{{ item.format_calendar_date_year }} | {{ item.day }}</td>
          <td></td>
          <td>{{ item.in_time }}</td>
          <td>{{ item.out_time }}</td>
          <td>{{ item.status }}</td>
        </tr>
        </tbody>
      </table>
      <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>

const props  = defineProps({
  tableItems     : Object,
});

const getRowStatus = (status) =>  {
  if (!status) return {};
  return {
    'bg-color': ['H', 'W', 'LT','L'].some(value => status.split('/').includes(value)),
    'bg-red': status === 'A',
  };
}

</script>

<style>
.bg-color{
  --bs-bg-opacity: 1;
  background-color: rgb(16 108 223 / 20%) !important;
}
.bg-red{
  --bs-bg-opacity: 1;
  background-color: rgba(223, 16, 95, 0.2) !important;
  background-color: rgb(165 45 90 / 12%) !important;
  color:red;
}
</style>

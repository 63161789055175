<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Receipt"
                        @onClickCloseButton="goToList"
                />

                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="receipt.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Received in</label>
                        <v-select
                                placeholder="Select Cash & Bank Account"
                                class="w-100"
                                v-model="debit.account_head_id"
                                :options="cashAndBack"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                        <div class="d-flex align-items-center justify-content-between filter-label">
                            <label for="colFormLabel" class="col-form-label">Party: </label>
                            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>

                        <AsyncSelect
                            placeholder="Select Party"
                            v-model="contactProfile"
                            :api-service="fetchContactProfiles"
                            :format-label="formatPatientLabel"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="receipt.business_id"
                        />
                    </div>
                </div>
            </div>
            <div class="card p-2">
                <AddFormElement
                        class="mb-2"
                        :accountHeads="accountHeads"
                        v-for="(item, index) in receipt.general_ledgers"
                        :key="index"
                        :index="index"
                        :item="item"
                        @onClose="onClose"
                />

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
                    <!-- <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control" :value="totalCredit"></div> -->
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="receipt.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="my-2 text-center">
                    <hr class="mb-1">
                    <button
                        :disabled="receiptLoading || saveNewLoader"
                        @click="saveReceipt(true)"
                        class="btn btn-primary me-1"
                    >
                        <div v-if="receiptLoading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save
                    </button>
                    <button
                            :disabled="saveNewLoader || receiptLoading"
                            @click="saveReceipt(false)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & New
                    </button>
                    <button
                        :disabled="receiptLoading || saveNewLoader"
                        @click="saveReceipt(false, true)"
                        class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & Print
                    </button>
                    <button
                            :disabled="saveNewLoader"
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :isFilterable="true"
            :companyRoles="companyRoles"
        />
    </div>
</template>

<script>
import handleReceipt from '@/services/modules/receipt'
import { inject } from 'vue'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/receipt/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import figureFormatter from "@/services/utils/figureFormatter";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {useStore} from "vuex";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleRole from "@/services/modules/role";
import pdfPrinterHelper from "@/services/utils/pdfPrinterHelper";


export default {
    name: 'ReceiptCreate',
    components: {
        ContactDropdownFilter,
        AsyncSelect,
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        cashAndBack: [],
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        companyRoles: [],
        paymentRes: {},
        contactProfile: null,
        debit: {
            account_head_id: null
        },
        receipt: {
            company_id:'',
            contact_profile_id: null,
            business_id: null,
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_type: 'receipt_voucher',
            general_ledgers: [
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                },
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                }
            ]
        }
    }),
    computed: {
        isLoading () {
            return this.receiptLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.receipt.general_ledgers
        },
        totalCredit () {
            let total = 0;
            this.general.map(i => {
                if(i.credit) total += i.credit
            })
            return total
        }
    },
    watch: {
        contactProfile(newContact) {
            if(newContact) {
                this.receipt.contact_profile_id = newContact.id;
            }
        }
    },
    methods: {
        
        async getCashAndBank() {
           // return;
            try {
                this.receiptLoading = true
                let res = await this.fetchCashAndBank(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.cashAndBack = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        async getAccountHeads() {
           // return;
            try {
                this.receiptLoading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        async getBusinesses(){
        try {
          let companyId = this.$route.params.companyId
          let res = await this.fetchBusinessList('?company_id=' + companyId)

          if(!res.status) {
          }
          if(res.status) {
            this.businesses = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }

      },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if (!withoutDate) {
                        this.receipt.date = new Date().toISOString().split('T')[0]
                    }
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },

        goToList() {
            this.$router.push({name: 'receipt-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.receipt.general_ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.receipt.general_ledgers.push({
                account_head_id: null,
                debit: null,
                credit: '',
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        async resetForm() {
            this.receipt = {
                company_id: '',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'receipt_voucher',
                general_ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
            this.contactProfile = null;
            this.debit = {
                account_head_id: null
            }
            await this.setVoucherNumberAndDate()
        },
        getFormData () {
            let formData = new FormData();
            Object.keys(this.receipt).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.receipt[i])
                }
            })
            let mop_account_head_id = this.debit.account_head_id;
            let generalLedgerData = this.general.map((ledger) => {
                ledger.contact_profile_id = this.receipt.contact_profile_id;
                ledger.business_id = this.receipt.business_id;
                ledger.mop_account_head_id = mop_account_head_id;
                return ledger
            })
            generalLedgerData.push({
                account_head_id: this.debit.account_head_id,
                debit: this.totalCredit,
                credit: null,
                note: this.receipt.note,
                tax_rate: null,
                taxable_amount: null,
                contact_profile_id: this.receipt.contact_profile_id,
                business_id: this.receipt.business_id
            })
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))
            return formData;
        },
        async saveReceipt (redirect = false, print = false) {
            this.receipt.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.receiptLoading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createReceipt(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    await this.resetForm()
                    if(redirect) this.goToList()
                    if(print) this.goToPrint(res.data.id)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
            } finally {
                this.receiptLoading = false
                this.saveNewLoader = false
            }
        },
        onOpenContactSearchModal () {
            this.$store.state.isModalOpenThree = true;
        },
        async getCompanyRoles () {
            const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
            if (res.status) {
                this.companyRoles = res.data
            }
        },
        onSearchContact (contact) {
            this.contactProfile = contact
        },
        goToPrint(id) {
            this.fetchSingleReceipt(id).then( (res) => {
                if(res){
                    this.paymentRes = res.data;
                }}).then (() => {
                this.generatePdf(this.$route.params.companyId, this.paymentRes, 'receipt')
            }).catch((err) => {
                console.log('Something went wrong', err);
            })
        },
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();
        const store = useStore()
        const { generatePdf } = pdfPrinterHelper();

        const {
            fetchAccountHeads,
            fetchCashAndBank,
            createReceipt,
            receiptLoading,
            fetchSingleReceipt
        } = handleReceipt()

        const {
            fetchContactProfiles,
        } = handleContact()

        const {
            fetchBusinessList,
        } = handleCBusinesses()

        const { formatPatientLabel } = useAsyncDropdownHelper();
        const { fetchCompanyDefaultRoles } = handleRole();

        return {
            store,
            fetchAccountHeads,
            fetchCashAndBank,
            fetchContactProfiles,
            fetchBusinessList,
            fetchSingleReceipt,
            createReceipt,
            decimalFormat,
            generatePdf,
            receiptLoading,
            showError,
            showSuccess,
            formatPatientLabel,
            fetchCompanyDefaultRoles
        }
    },

    async mounted() {
        await this.getCashAndBank()
        await this.getAccountHeads()
        await this.getBusinesses()
        await this.setVoucherNumberAndDate()
        await this.getCompanyRoles()
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>

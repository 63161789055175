<template>
  <div class="card stock-card">
    <div class="card p-2 stock-card-background">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Stock Register Detailed Report"
      />

      <div class="row mt-2">
        <div class="col-md-4">
          <label for="colFormLabel">
              Product/Service
          </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="product"
            label="text"
            :format-label="option => option.text"
            :additional-option="additionalProductOption"
          />
        </div>
        <div class="col-md-4">
          <label for="colFormLabel">
              Inventory Location
          </label>
          <v-select
              placeholder="Select Inventory Location"
              v-model="location"
              :options="locations"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-md-4">
            <label for="colFormLabel">
              Supplier
            </label>
            <AsyncSelect
                placeholder="Supplier Name, ID, Mobile No"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :additional-query="{'type' : 'supplier'}"
                :format-label="formatPatientLabel"
            />
        </div>
      </div>
      <div class="col-12 col-lg-10 col-xl-10 col-xxl-10 mt-2">
        <DateQuerySetter
            btnTitle="Go"
            @onClickGo="onClickGo"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="px-2">
          <button
            @click="onChangeTab('quantity')"
            :class="selectedTab === 'quantity' ? 'btn-primary' : 'btn-outline-secondary'"
            class="btn btn-block waves-effect waves-float waves-light me-1"
          >Quantity
          </button>
          <button
            @click="onChangeTab('value')"
            :class="selectedTab === 'value' ? 'btn-primary' : 'btn-outline-secondary'"
            class="btn btn-block waves-effect waves-float waves-light"
          >Value
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-end px-2 gap-1">
          <button @click="exportTable" class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect">Export</button>
          <button @click="goToPrint" :disabled="loading" class="btn btn-primary btn-block waves-effect waves-float waves-light">Print</button>
        </div>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <StockRegisterTable 
        :balances="stockRegisterData" 
        :reportTotalRow="reportTotalRow"
        :stockType="selectedTab"
      />

    </div>

    <div class="p-2">
      <Pagination
        :offset="offset"
        @onPageChange="onPageChange"
        ref="pagination"
      />
    </div>

    <Loader v-if="reportLoading"/>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import handleReport from '@/services/modules/inventory';
import handleBusinessesLocations from '@/services/modules/businessesLocations';
import handlePurchase from '@/services/modules/purchase';
import { useAsyncDropdownHelper } from '@/services/utils/asyncDropdownHelper';
import handleContact from '@/services/modules/contact';

import TitleButton from '@/components/atom/TitleButton';
import DateQuerySetter from '@/components/atom/DateQuerySetter';
import StockRegisterTable from '@/components/molecule/company/stock-register/StockRegisterDetailedReport';
import Loader from '@/components/atom/LoaderComponent';
import AsyncSelect from '@/components/molecule/input-field/AsyncSelect.vue';
import Pagination from '@/components/atom/Pagination.vue';
import handleCompany from "@/services/modules/company";
import pdfPrinter from '@/services/utils/pdf/StockDetailedReport';
import { formatDateToDdMmYy } from '@/services/utils/global';

const { fetchHome, getStockRegisterDetailedReport, reportLoading } = handleReport();
const { fetchBusinessLocationsList } = handleBusinessesLocations();
const { fetchProductList } = handlePurchase();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF } = pdfPrinter();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const $store = useStore();
const router = useRouter();
const route = useRoute();

const productType = ref('');
const product = ref('');
const location = ref(null);
const stockRegisterData = ref([]);
const reportTotalRow = ref([]);
const locations = ref([]);
const products = ref([]);
const productTypes = ref([]);
const homeContent = ref({});
const contactProfile = ref(null);
const offset = ref(20);
const page = ref(1);
const additionalProductOption = ref([{ id: '', text: 'All' }]);
const loading = ref(false);
const allReports = ref([]);
const companyInfo = ref({});

const companyId = computed(() => route.params.companyId);
const start = computed(() => route.query.start);
const end = computed(() => route.query.end);
const token = computed(() => localStorage.getItem('token'));
const selectedTab = computed(() => route.query.tab || 'quantity');

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  });
};

const setPaginationData = (data) => {
  $store.commit('setPaginationData', data);
};

const getQuery = (onMounted = false, currentPage = 1) => {
  const queryObject = {
    company_id: companyId.value,
    start_date: start.value,
    end_date: end.value,
    product_type: productType.value,
    product: product.value ? product.value.id : '',
    location: onMounted ? '' : location.value,
    offset: offset.value,
    contact_profile_id: contactProfile.value ? contactProfile.value.id : '',
    page: currentPage,
    stock_type: selectedTab.value
  };
  return '?' + new URLSearchParams(queryObject).toString();
};

const onChangeTab = async (tab) => {
  $store.commit("resetPagination");
  await router.push({
    name: route.name,
    query: { ...route.query, tab, page: 1 },
    params: route.params 
  });
  getStockRegisterBalance(getQuery(false, 1));
}

const onClickGo = () => {
  getStockRegisterBalance(getQuery(false, 1));
}

const getStockRegisterBalance = async (query = '', isShowAll = false) => {
  try {
    reportLoading.value = true;
    const res = await getStockRegisterDetailedReport(query);

    if (!res.status) {
      stockRegisterData.value = [];
      showError(res.message);
    }

    if(res.status && isShowAll) {
      allReports.value = res.data;
      return
    }

    if (res.status) {
      stockRegisterData.value = res.data.data;
      reportTotalRow.value = res.data.report_total_row;
      setPagination(res.data);
    }
  } catch (err) {
    stockRegisterData.value = [];
    const errorMsg = err.response?.data?.message || 'Something is wrong. Check your connectivity!!';
    showError(errorMsg);
  } finally {
    reportLoading.value = false;
  }
};

const exportTable = () => {
  const queryObject = {
    company_id: companyId.value,
    start_date: start.value,
    end_date: end.value,
    product_type: productType.value,
    product: product.value ? product.value.id : '',
    location: location.value,
    offset: offset.value,
    page: page.value,
    _token: token.value,
    is_export: 1,
    stock_type: selectedTab.value
  };

  const queryString = new URLSearchParams(queryObject).toString();
  const url = `${process.env.VUE_APP_BASE_URL}/export/inventory/stock-register-detailed-report?${queryString}`;
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
};

const goToPrint = async () => {
  allReports.value = [];
  let query = getQuery(false, 1);
  query += '&is_all=1';

  const filters = getFilters();

  loading.value = true;

  await getStockRegisterBalance(query, true).then(() => {
      if(! allReports.value.length) return;
      exportToPDF(companyInfo.value, allReports.value, filters, selectedTab.value);
  })

  loading.value = false
}

const getFilters = () => {
    const data = {
        supplier: contactProfile.value ? contactProfile.value.name : '',
        location: '',
        product: product.value ? product.value.text : '',
        start_date: formatDateToDdMmYy(start.value),
        end_date: formatDateToDdMmYy(end.value)
    }

    const locationInfo = locations.value.find(l => l.id === location.value);
    if(locationInfo) data.location = locationInfo.text;

    return data;
}

const onPageChange = async (currentPage) => {
  page.value = currentPage;
  const routeQuery = { ...route.query, page: currentPage };
  await router.push({ path: route.path, query: routeQuery });
  await getStockRegisterBalance(getQuery(false, currentPage));
};

const makeProductTypesDropdownOptions = () => {
  productTypes.value.push({ id: '', text: 'All' });
  for (const key in homeContent.value.product_type) {
    productTypes.value.push({ id: key, text: homeContent.value.product_type[key] });
  }
};

const getCompany = async () => {
    await fetchCompanyInfo(route.params.companyId).then(res => {
        if (!res.status) return
        companyInfo.value = res.data
    }).catch(err => {
        console.log(err)
    })
}

onMounted(() => {
  reportLoading.value = true;
  const companyQuery = `?company_id=${companyId.value}`;
  Promise.all([
    fetchHome().then((res) => {
      homeContent.value = res.data;
      makeProductTypesDropdownOptions();
    }),
    fetchBusinessLocationsList(companyQuery).then((res) => {
      if (res.data) {
        locations.value = res.data;
      }
    }),
  ])
    .then(() => {
      productType.value = 'finished_goods';
      location.value = '';
      getStockRegisterBalance(getQuery());
      getCompany();
    })
    .catch(() => {});
});
</script>

<style scoped>
.stock-card {
  min-height: calc(100vh - 158px) !important;
}
.stock-card-background {
  background: #DBE7F2 !important;
}
</style>
  
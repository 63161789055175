<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Leave Type</th>
          <th>Leave Allocation</th>
          <th>Opening Leave Balance</th>
          <th>Leave Taken</th>
          <th>Leave Adjustment</th>
          <th>Closing Leave Balance</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.leave_policy_name }}</td>
          <td>{{ item.number_of_days }}</td>
          <td>{{ item.opening_leave_balance }}</td>
          <td>{{ item.total_leave_taken_from_date_range }}</td>
          <td></td>
          <td>{{ item.closing_leave_balance }}</td>
          <td>
            <button v-if="item.closing_leave_balance > 0" class="btn btn-primary btn-sm"
                    @click="openApplyModal(item)"
                    style="cursor: pointer"
            >
              Apply
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
    </div>
    <ApplyLeaveModal
        v-if="store.state.isModalOpenThree"
        :policyBalanceId="policyBalanceId"
        @getMyLeaveList="getMyLeaveList"
    />

  </div>
</template>

<script setup>

import {useStore} from "vuex";
import {ref} from "vue";
import ApplyLeaveModal from '@/components/atom/hrPayroll/ApplyLeaveModal.vue'

const props  = defineProps({
  tableItems     : Object,
  getMyLeaveList : Function
});
const store = useStore()
const policyBalanceId = ref(null)

const openApplyModal = (item) => {
  policyBalanceId.value = item.id
  store.state.isModalOpenThree = true;
}

</script>

<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="mt-2">
        <div class="row">
           <div class="justify-content-center">
             <h3 class="highlight">My Attendance (Last 7 days)</h3>
             <p v-if="false" class="text-black"><strong>Total Days = 31</strong></p>
             <p v-if="false" class="text-black">
               P = 10 | LT = 1 | LV = 2 | W = 3 | H = 1 | A = 2
             </p>
           </div>
        </div>

        <div class="row">
          <div class="justify-content-end">
            <a  @click="goToPage('my-attendance')" class="float-end text-primary fw-bold p-1">See Details</a>
          </div>
        </div>

       <div class="row">
         <div class="col-12">
           <div class="table-responsive">
             <table class="table table-bordered text-center align-middle custom-table">
               <thead class="text-secondary">
                 <tr class="text-black">
                   <th>Date</th>
                   <th>In Time</th>
                   <th>Out Time</th>
                   <th>Status</th>
                 </tr>
               </thead>
               <tbody>
                 <tr v-for="(item, i) in tableItems" :key="i"
                     :class="getRowStatus(item.status)"
                     class="highlight-row">
                   <td class="custom-td">{{ item.format_calendar_date }}</td>
                   <td class="custom-td">{{ item.in_time }}</td>
                   <td class="custom-td">{{ item.out_time }}</td>
                   <td class="custom-td">{{ item.status }}</td>
                 </tr>
               </tbody>
             </table>
           </div>
         </div>
       </div>

     </div>
    </div>
      <Loader v-if="loading"/>
   </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import {useRoute, useRouter} from "vue-router";
import Loader from '@/components/atom/LoaderComponent'
import handleRedirectToPage from "@/services/modules/redirectToPage";

const store = useStore()
const $route = useRoute();
const router = useRouter();
const tableItems = ref([])
const loading = ref(false)
const {goToPage} = handleRedirectToPage()

const {
  fetchAttendanceList,
} = handleHrPayroll()

function getQuery() {
  return `?company_id=${$route.params.companyId}`;
}

const fetchAttendance = () => {
  loading.value = true
  let query = getQuery();
  query += `&limit_days=7`
  query += `&is_all=true`

  const fetchAttendance = fetchAttendanceList(query);
  fetchAttendance.then(res => {
     if (!res.status){
       tableItems.value = [];
       return;
     }

     tableItems.value = res.data;
  }).finally(() => {
    loading.value = false
  });
}

const getRowStatus = (status) =>  {
  if (!status) return {};
  return {
    'bg-color': ['H', 'W', 'LT','L'].some(value => status.split('/').includes(value)),
    'bg-red': status === 'A',
  };
}

onMounted(() => {
  fetchAttendance()
})

</script>

<style>
.status-red {
  color: red;
  font-weight: bold;
}
.highlight-row {
  background-color: #f8f9fa;
}
.custom-table {
  border: 1px solid #ddd;
}
.highlight {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.table .custom-td{
   padding: 2px;
}
.bg-color{
  --bs-bg-opacity: 1;
  background-color: rgb(16 108 223 / 20%) !important;
}
.bg-red{
  --bs-bg-opacity: 1;
  background-color: rgba(223, 16, 95, 0.2) !important;
  background-color: rgb(165 45 90 / 12%) !important;
  color:red;
}
</style>
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const pdfPrinter = () => {
    const store = useStore();
    const {commaFormat} = figureFormatter();
    const {getHeader, getFooter} = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, saleInvoiceData,startDate = '', endDate = '') => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [10, 100, 10, 80],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(saleInvoiceData,startDate,endDate),

            styles: {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'Sale Register Invoice (' + startDate + ' to ' + endDate + ')',
            }
        }

        pushIntoTable(doc, saleInvoiceData)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (saleInvoiceData,startDate,endDate) => {
        return [
            {
                text:  'Sale Register Invoice (' + startDate + ' to ' + endDate + ')',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                fontSize: 12,
                decoration: 'underline'
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: [ '10%', '10%', '10%', '9%', '8%', '9%', '8%', '9%', '9%', '9%', '9%' ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const pushIntoTable = (doc, saleInvoiceData) => {
        const tableItems = saleInvoiceData.data;
        const tableHeader = getTableHeader();
        doc.content[1].table.body.push(tableHeader);

        tableItems.forEach(item => {
            const rowData = getRowData(item);
            return doc.content[1].table.body.push(rowData);
        });

        const getLastRow = getTotal(saleInvoiceData.total)
        doc.content[1].table.body.push(getLastRow);
    }

    const getTableHeader = () => {
        const tableHeader = [
            {text: 'Date'},
            {text: 'Invoice Number'},
            {text: 'Client Name'},
            {text: 'Invoice Amount', alignment: 'right'},
            {text: 'VAT Amount', alignment: 'right'},
            {text: 'Total Amount', alignment: 'right'},
            {text: 'Discount Amount', alignment: 'right'},
            {text: 'Paid Amount', alignment: 'right'},
            {text: 'Due Amount', alignment: 'right'},
            {text: 'COGS', alignment: 'right'},
            {text: 'Gross Profit', alignment: 'right'},
        ]

        return tableHeader
    }
    const getRowData = (item) => {
        const dueAmount = parseFloat(item.total_amount) - parseFloat(item.net_receipt_amount) - parseFloat(item.ait_deducted) - parseFloat(item.vat_deducted) - parseFloat(item.discount)

        const tableRow = [
            {text: item.date},
            {text: item.bill_number},
            {text: item.contact_name},
            {text: commaFormat(item.subtotal_amount), alignment: 'right'},
            {text: item.vat_amount ? parseFloat(item.vat_amount).toFixed(2) : 0, alignment: 'right'},
            {text: item.total_amount ? parseFloat(item.total_amount).toFixed(2) : 0, alignment: 'right'},
            {text: item.discount ? parseFloat(item.discount).toFixed(2) : 0, alignment: 'right'},
            {text: item.paid_amount ? parseFloat(item.paid_amount).toFixed(2) : 0, alignment: 'right'},
            {text: dueAmount ? parseFloat(dueAmount).toFixed(2) : 0, alignment: 'right'},
            {text: item.stocks_sum_stock_value ? parseFloat(item.stocks_sum_stock_value).toFixed(2) : 0, alignment: 'right'},
            {text: item.stocks_sum_profit_value ? parseFloat(item.stocks_sum_profit_value).toFixed(2) : 0, alignment: 'right'},
        ]

        return tableRow
    }

    const getTotal = (totalValue) => {

        const lastRow = [
            {
                text: 'Total',
                alignment: 'right',
                colSpan: 3
            },

            '','',

            {
                text: totalValue.total_subtotal_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_vat_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_sum_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_discount_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_paid_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_due_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_stock_value,
                alignment: 'right'
            },

            {
                text: totalValue.total_profit_value,
                alignment: 'right'
            }
        ]

        return lastRow
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;
<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="px-2 mt-1">
      <div class="card card-height">
        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Employee</label>
              <v-select
                  placeholder="Select Employee"
                  v-model="formData.contact_profile_id"
                  :options="contacts"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Leave Policy</label>
              <v-select
                  placeholder="Select Leave Policy"
                  v-model="formData.leave_policy_id"
                  :options="leavePolicy"
                  label="name"
                  @update:modelValue="fetchLeavePolicy"
                  :reduce="name => name.id"
              />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="form-label">Number Of Days</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="number"
                  placeholder="Number Of Days...."
                  v-model="formData.number_of_days"
              >
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="form-label">Date From</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="date"
                  placeholder="Date From...."
                  v-model="formData.date_from"
              >
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="form-label">Date To</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="date"
                  placeholder="Date From...."
                  v-model="formData.date_to"
              >
            </div>

            <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Status</label>
              <v-select
                  placeholder="Select Policy"
                  v-model="formData.status"
                  :options="status"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";
import handleContact from '@/services/modules/contact'

const store = useStore();
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Leave Balance")
let contacts = ref([])
let leavePolicy = ref([])
let leaveBalanceEditData = ref({})
const status = ref([]);
let leaveBalanceData = {}
let formData = ref({
  company_id: companyId,
  status: 'active',
  contact_profile_id:'',
  leave_policy_id:'',
  number_of_days:'',
  date_from:'',
  date_to:'',
})

function navigateToListPage() {
  delete route.query.leaveBalanceId;
  router.push({name: `leave-balance`, params: route.params, query: route.query});
}

const {
  createLeaveBalance,
  fetchSingleLeavePolicy,
  fetchSingleLeaveBalance,
  updateLeaveBalance,
  fetchTypes,
  fetchLeavePolicyList
} = handleHrPayroll()

const { fetchContactProfiles } = handleContact()

function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

function handleSubmit(redirect) {
  leaveBalanceData.company_id  = formData.value.company_id;
  leaveBalanceData.status = "active";
  leaveBalanceData.contact_profile_id = formData.value.contact_profile_id;
  leaveBalanceData.leave_policy_id = formData.value.leave_policy_id;
  leaveBalanceData.number_of_days = formData.value.number_of_days;
  leaveBalanceData.date_from = formData.value.date_from;
  leaveBalanceData.date_to = formData.value.date_to;

  createLP(redirect);
}

function handleUpdate(redirect) {
  leaveBalanceData.id = formData.value.id;
  leaveBalanceData.company_id  = formData.value.company_id;
  leaveBalanceData.status = formData.value.status;
  leaveBalanceData.contact_profile_id = formData.value.contact_profile_id;
  leaveBalanceData.leave_policy_id = formData.value.leave_policy_id;
  leaveBalanceData.number_of_days = formData.value.number_of_days;
  leaveBalanceData.date_from = formData.value.date_from;
  leaveBalanceData.date_to = formData.value.date_to;

  updateLPData(redirect);
}

function createLP(redirect = true) {
  createLeaveBalance(leaveBalanceData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message)
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function updateLPData(redirect = true) {
  updateLeaveBalance(leaveBalanceData, leaveBalanceData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

const fetchLeavePolicy = () => {
  let query = getQuery();
  fetchSingleLeavePolicy(formData.value.leave_policy_id, query).then(res => {
    if (!res.status) {
      return
    }
    formData.value.number_of_days = res.data.number_of_days;
  })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Leave Balance";
  formData.value.id =  leaveBalanceEditData.value.id;
  formData.value.contact_profile_id = leaveBalanceEditData.value.contact_profile_id;
  formData.value.leave_policy_id = leaveBalanceEditData.value.leave_policy_id;
  formData.value.number_of_days = leaveBalanceEditData.value.number_of_days;
  formData.value.date_from = leaveBalanceEditData.value.date_from;
  formData.value.date_to = leaveBalanceEditData.value.date_to;
  formData.value.status = leaveBalanceEditData.value.status;
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      status.value = [];
      return
    }

    if (res.data && res.data.status) {
      status.value = res.data.status;
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true

  const query = getQuery()
  const contactQuery = query + '&type=employee'
  const contactRes   = fetchContactProfiles(contactQuery)

  const leavePolicyQuery = query + '&is_all=true&status=active'
  const leavePolicyRes = fetchLeavePolicyList(leavePolicyQuery)

  Promise.all([
    getTypes(),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    leavePolicyRes.then(res => {
      if (res.data) leavePolicy.value = res.data
    }),
  ]).finally(() => {
    loader.value = false
  })

  if(route.query.leaveBalanceId && route.query.leaveBalanceId !== '' && route.query.leaveBalanceId !== undefined){
    isCreate.value = false;
    const fetchLeaveBalance = fetchSingleLeaveBalance(route.query.leaveBalanceId, getQuery());
    fetchLeaveBalance.then(res2 => {
      leaveBalanceEditData.value = res2.data;
      leaveBalanceEditData.value = res2.data;
      setFormData();
    });
  }

}

onMounted(() => {
  onPageReload();
})
</script>
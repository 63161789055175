import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const pdfPrinter = () => {
    const store = useStore();
    const {commaFormat} = figureFormatter();
    const {getHeader, getFooter} = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, saleItemData,isItem = false,isCustomer = false,startDate = '', endDate = '') => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [10, 100, 10, 80],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(saleItemData,isItem,isCustomer,startDate,endDate),

            styles: {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'Sale Register' + (isItem ? ' Item ' : (isCustomer ? ' Customer ' : '')) + '(' + startDate + ' to ' + endDate + ')',
            }
        }

        pushIntoTable(doc, saleItemData,isItem,isCustomer)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (saleItemData,isItem,isCustomer,startDate,endDate) => {
        return [
            {
                text: 'Sale Register' + (isItem ? ' Item ' : (isCustomer ? ' Customer ' : '')) + '(' + startDate + ' to ' + endDate + ')',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                fontSize: 12,
                decoration: 'underline'
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: getWidth(isItem,isCustomer)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const getWidth = (isItem,isCustomer) => {
        if (isItem){
            return [ '15%', '10%', '15%', '15%', '15%', '15%', '15%' ]
        }
        if (isCustomer){
            return [ '15%','15%', '10%', '12%', '12%', '12%', '12%', '12%' ]
        }
    }

    const pushIntoTable = (doc, saleItemData,isItem,isCustomer) => {
        const tableItems = saleItemData.data;
        const tableHeader = getTableHeader(isItem,isCustomer);
        doc.content[1].table.body.push(tableHeader);

        tableItems.forEach(item => {
            const rowData = getRowData(item,isItem,isCustomer);
            return doc.content[1].table.body.push(rowData);
        });

        const getLastRow = getTotal(saleItemData.total,isItem,isCustomer)
        doc.content[1].table.body.push(getLastRow);
    }

    const getTableHeader = (isItem,isCustomer) => {
        let tableHeader = [];
        if (isItem) {
            tableHeader = [
                {text: 'Item'},
            ];
        }

        if (isCustomer) {
            tableHeader = [
                {text: 'Customer Name'},
                {text: 'Contact No'},
            ];
        }

        tableHeader.push(
            {text: 'Sales Qty', alignment: 'right'},
            {text: 'Total Amount', alignment: 'right'},
            {text: 'VAT Amount', alignment: 'right'},
            {text: 'Sales Amount', alignment: 'right'},
            {text: 'COGS', alignment: 'right'},
            {text: 'Gross Profit', alignment: 'right'},
        )
        return tableHeader;
    }
    const getRowData = (item,isItem,isCustomer) => {
        let tableRow = [];
        if (isItem) {
            tableRow = [
                {text: item.product_name},
            ];
        }

        if (isCustomer) {
            tableRow = [
                {text: item.customer_name},
                {text: item.phone},
            ];
        }
        tableRow.push(
            {text: item.net_quantity},
            {text: commaFormat(item.total_sale_amount), alignment: 'right'},
            {text: commaFormat(item.total_vat_amount), alignment: 'right'},
            {text: commaFormat(item.sales_amount), alignment: 'right'},
            {text: commaFormat(item.stocks_sum_stock_value), alignment: 'right'},
            {text: commaFormat(item.stocks_sum_profit_value), alignment: 'right'},
         )
        return tableRow
    }

    const getTotal = (totalValue,isItem,isCustomer) => {

        let lastRow = [];
        if (isItem){
            lastRow = [
                {
                    text: 'Total',
                    alignment: 'right',
                },
            ]
        }

        if (isCustomer){
            lastRow = [
                {
                    text: 'Total',
                    alignment: 'right',
                    colSpan:2
                },
                ''
            ]
        }

        lastRow.push(
            {
                text: totalValue.total_net_quantity,
                alignment: 'right'
            },

            {
                text: totalValue.total_sale_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_vat_amount,
                alignment: 'right'
            },

            {
                text: totalValue.total_sales_amount,
                alignment: 'right'
            },

            {
                text: totalValue.stocks_sum_stock_value,
                alignment: 'right'
            },

            {
                text: totalValue.stocks_sum_profit_value,
                alignment: 'right'
            },
        )

        return lastRow
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;
<template>
    <div class="row">
        <div class="col-12 col-md-12 note-head">
            <div class="note-title">
                <v-select
                    v-if="from === 'group-template'"
                    placeholder="Select Note Type"
                    label="name"
                    :options="noteTypes"
                    v-model="note.value"
                    :reduce="text => text.value"
                    @option:selected="onSelectNoteType"
                />
                <h4 v-else>{{ note.name }}</h4>
            </div>
            <div v-if="from === 'group-template'">
                <div class="input-group input-group-merge group-template">
                    <input
                        type="text"
                        name="name" 
                        placeholder="Enter template name"
                        class="form-control"
                        v-model.trim="note.template_name"
                    />
                </div>
            </div>
            <div class="note-btn" v-if="!note.isHideAddBtn">
                <button v-if="from === 'group-template'" @click="removeNoteCard(note, index)" type="button" class="btn btn-outline-danger btn-sm btn-block me-1">
                    <i class="fas fa-trash"></i>
                </button>
                <button @click="onOpenModal(note, index)" type="button" class="btn btn-outline-primary btn-sm btn-block">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
        <div class="col-21">
            <hr class="mt-0">
        </div>
        <div class="col-12 col-md-12 note-body">
            <div class="card">
                <div class="card-body" v-if="!isPhc">
                    <div class="medicine" v-for="(item, noteIndex) in note.selectedNotes" :key="noteIndex">
                        <div class="medicine-name" v-if="isSpecialNote">
                            <span class="sr-no">{{ noteIndex + 1 }}. </span>
                            <span class="name">{{ item.name }}</span>
                            <span v-if="note.value === 'investigation_history'">
                                <input
                                    v-model.trim="item.result" type="text"
                                    class="note-input-investigation-history form-control"
                                    ref="input"
                                >
                            </span>
                        </div>
                        <div class="medicine-name card-body m-1" v-else>
                            <span v-if="currentIndex !== noteIndex" @click="setEdit(item, noteIndex)" class="name">
                              <textarea class="form-control" readonly>{{ item }}</textarea>
                            </span>
                            <span v-show="currentIndex === noteIndex">
                                <textarea
                                    v-model.trim="editValue" type="text"
                                    @blur="onUpdate(item, noteIndex)"
                                    class="note-input form-control"
                                    ref="input"
                                ></textarea>
                            </span>
                        </div>
                        <div @click="onRemoveNote(item, noteIndex)" class="action" v-if="!note.isHideAddBtn">
                            <i class="far fa-times-circle close"></i>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="isPhc">
                    <ul class="no-dots " v-for="(item, phcIndex) in note.phcNoteList" :key="phcIndex">
                        <li>{{ item.name }}: {{ item.value }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 note-footer pb-2" v-if="from === 'group-template'">
            <SubmitCancelBtn
                class="text-right"
                @onSubmit="onSaveTemplate"
                :loading="loading"
                :isHideCancelBtn="true"
                :disabled="note.selectedNotes.length === 0"
            />
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import { computed, inject, watch } from "vue";
import { ref } from "vue"
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
import handleHospital from '@/services/modules/hospital';
import { useRoute } from "vue-router";
import { convertToTitleCase } from '@/services/utils/global';
import Loader from '@/components/atom/LoaderComponent'

const props = defineProps({
    note: Object,
    index: {
        type: Number,
        default: -1,
    },
    openModal: Function,
    isPhc:{
        type: Boolean,
        default: false,
    },
    from: {
        type: String,
        default: ''
    },
    noteTypes: {
        type: Array,
        default: () => []
    },
    onSave: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['openModal', 'onSave'])
const currentIndex = ref(-1);
const editValue = ref(null);
const input = ref(null)
const route = useRoute();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const { storeGroupTemplate, updateGroupTemplate, deleteGroupTemplate } = handleHospital();

const isSpecialNote = computed(() => {
    return props.note.value === 'investigation_history' || props.from === 'group-template';
})

const onOpenModal = (note, index = -1) => {
    if(note.value){
        note.name = convertToTitleCase(note.value);
    }
    emit('openModal', {note, index})
}

const onRemoveNote = (noteName, selectedIndex) => {
    props.note.selectedNotes.splice(selectedIndex, 1)
    const index = props.note.newNotesByDoctor.indexOf(noteName);
    if(index < 0) return;
    props.note.newNotesByDoctor.splice(index);
}

const removeNoteCard = (note, index) => {
    if(! confirm('Are you sure to remove this template?')) return;
    if(! note.id) {
        showSuccess('Template removed successfully');
        emit('onSave');
        return;
    }
    removeGroupTemplate(note.id);
}

const removeGroupTemplate = async (id) => {
    const query = `?company_id=${route.params.companyId}`;

    await deleteGroupTemplate(query, id).then((res) => {
        if (!res.status) return showError(res.message);
        showSuccess(res.message);
        emit('onSave')
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const setEdit = (note, index) => {
    if(props.note.isHideAddBtn || isSpecialNote.value) return;

    currentIndex.value = index;
    editValue.value = note;
    if (input.value && input.value[index]) {
        input.value[index].focus();
    }
}

const onUpdate = (item, index) => {
    currentIndex.value = -1;
    props.note.selectedNotes.splice(index, 1, editValue.value)
}

const onSaveTemplate = async () => {
    const data = formatFormData();

    const id = props.note.id || null;
    const templateAction = id ? updateGroupTemplate : storeGroupTemplate;

    if(!data.name){
        return showError('Please enter name');
    }

    if(! data.template_type){
        return showError('Please select template type');
    }

    if(data.notes.length === 0){
        return showError('Please add at least one item.');
    }

    loading.value = true;
    await templateAction(data, id).then((res) => {
        if (!res.status) return showError(res.message);
        showSuccess(res.message);
        emit('onSave')
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formatFormData = () => {
    const formattedData = {};

    formattedData.name = props.note.template_name;
    formattedData.company_id = route.params.companyId;
    formattedData.template_type = props.note.value;
    formattedData.notes = props.note.selectedNotes.map((item) => {
            return {
                id: item.template_id || null,
                templateable_id: item.template_id ? item.templateable_id : item.id,
                additional_info: {}
            }
        });

    return formattedData;
}

const onSelectNoteType = () => {
    props.note.selectedNotes = [];
}

watch(() => props.note.value, (newValue, oldValue) => {
    if(props.from === 'group-template' && !newValue) {
        props.note.selectedNotes = [];
    }
})
</script>

<style scoped>
.note-title {
    min-width: 200px;
}
.group-template {
    width: 180%;
    margin-left: -80%;
}
.no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.note-input{
    position: absolute;
    width: 78%;
    padding: 0.271rem 1rem;
    display: inherit;
    top: -5px;
    left: 3%;
}
.note-input-investigation-history{
    position: absolute;
    width: 43%;
    padding: 0.271rem 1rem;
    display: inherit;
    top: -5px;
    left: 52%;
}
.note-body .card-body{
    padding: 0 2%;
}
.note-head {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 1%;
}
.action .close {
    color: red;
    cursor: pointer;
}
.note-body .card {
    background: #f9f9f9;
    min-height: 100px;
    margin-bottom: 1rem;
}
.medicine {
    display: flex;
    justify-content: space-between;
    margin: 3% 0;
    align-items: center;
    position: relative;
}
</style>
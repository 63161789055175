<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="px-2 mt-1">
      <div class="card card-height">
        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Attendance Policy</label>
              <v-select
                  placeholder="Select Policy"
                  v-model="formData.attendance_policy_master_id"
                  :options="attendancePolicyList"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Branch</label>
              <v-select
                  placeholder="Select Branch"
                  v-model="formData.branch_id"
                  :options="branchList"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Department</label>
              <v-select
                  placeholder="Select Department"
                  v-model="formData.department_id"
                  :options="departmentList"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Role</label>
              <v-select
                  placeholder="Select Role"
                  v-model="formData.role_id"
                  :options="roleListData"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Employee</label>
              <v-select
                  placeholder="Select Employee"
                  v-model="formData.contact_profile_id"
                  :options="contacts"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="form-label">Effective Date</label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="date"
                  placeholder=""
                  v-model="formData.effective_date"
              >
            </div>
            <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Status</label>
              <v-select
                  placeholder="Select Policy"
                  v-model="formData.status"
                  :options="status"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import handleContact from '@/services/modules/contact'
import {useStore} from "vuex";
import handleBranches from "@/services/modules/procurement/organization-settings/branch";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handleRole from "@/services/modules/role";

const store = useStore();
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Attendance Policy Allocation")
let contacts = ref([])
let locations = ref([])
let accountPayable = ref([])
let attendancePolicyEditData = ref({})
let attendancePolicyList = ref([])
let branchList = ref([])
let departmentList = ref([])
let roleListData = ref([])
const contactProfile = ref(null);
const status = ref([]);

let policyData = {}
let formData = ref({
  company_id: companyId,
  status: 'active',
  contact_profile_id:'',
  attendance_policy_master_id:'',
  branch_id:'',
  department_id:'',
  role_id:'',
  effective_date:'',
})

function navigateToListPage() {
  delete route.query.allocationId;
  router.push({name: `attendance-policy-allocation`, params: route.params, query: route.query});
}
const { fetchContactProfiles } = handleContact()
const { fetchBranchList } = handleBranches()
const { fetchDepartmentList } = handleDepartments()
const { roleList } = handleRole()
const {
  fetchAttendancePolicyList,
  createAttendancePolicyAllocation,
  fetchSingleAttendancePolicyAllocation,
  updateAttendancePolicyAllocation,
  fetchTypes,
} = handleHrPayroll()

function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

function handleSubmit(redirect) {
  policyData.company_id  = formData.value.company_id;
  policyData.status = "active";
  policyData.contact_profile_id = formData.value.contact_profile_id;
  policyData.attendance_policy_master_id = formData.value.attendance_policy_master_id;
  policyData.branch_id = formData.value.branch_id;
  policyData.department_id = formData.value.department_id;
  policyData.role_id = formData.value.role_id;
  policyData.effective_date = formData.value.effective_date;
  createAP(redirect);
}

function handleUpdate(redirect) {
  policyData.id = formData.value.id;
  policyData.company_id  = formData.value.company_id;
  policyData.status = formData.value.status;
  policyData.contact_profile_id = formData.value.contact_profile_id;
  policyData.attendance_policy_master_id = formData.value.attendance_policy_master_id;
  policyData.branch_id = formData.value.branch_id;
  policyData.department_id = formData.value.department_id;
  policyData.role_id = formData.value.role_id;
  policyData.effective_date = formData.value.effective_date;

  updateAPData(redirect);
}

function createAP(redirect = true) {
  createAttendancePolicyAllocation(policyData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        if (redirect) navigateToListPage()

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function updateAPData(redirect = true) {
  updateAttendancePolicyAllocation(policyData, policyData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Attendance Policy Allocation";
  formData.value.id =  attendancePolicyEditData.value.id;
  formData.value.contact_profile_id = attendancePolicyEditData.value.contact_profile_id;
  formData.value.attendance_policy_master_id = attendancePolicyEditData.value.attendance_policy_master_id;
  formData.value.branch_id = attendancePolicyEditData.value.branch_id;
  formData.value.department_id = attendancePolicyEditData.value.department_id;
  formData.value.role_id = attendancePolicyEditData.value.role_id;
  formData.value.effective_date = attendancePolicyEditData.value.effective_date;
  formData.value.status = attendancePolicyEditData.value.status;
}

const getAttendancePolicy = async () => {
  let query = getQuery();
  query += '&is_all=true'
  query += '&status=active'

  attendancePolicyList.value = []

  await fetchAttendancePolicyList(query).then(res => {
    if (!res.status){
      attendancePolicyList.value = []
      return
    }
    attendancePolicyList.value = res.data;

  }).catch(err => {
    console.log(err)
  })
}

  const getBranchList = async () => {
    let query = getQuery();

    branchList.value = []

    await fetchBranchList(query).then(res => {
      if (!res.status){
        branchList.value = []
        return
      }
      branchList.value = res.data;
    }).catch(err => {
      console.log(err)
    })
  }

const getDepartmentList = async () => {
  let query = getQuery();

  departmentList.value = []

  await fetchDepartmentList(query).then(res => {
    if (!res.status){
      departmentList.value = []
      return
    }
    departmentList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

const getRoleList = async () => {
  let companyId = route.params.companyId;

  roleListData.value = []

  await roleList(companyId).then(res => {
    if (!res.status){
      roleListData.value = []
      return
    }
    res.data.forEach((item) => {
      roleListData.value.push({
        id: item.id,
        name: item.label
      });
    })
  }).catch(err => {
    console.log(err)
  })
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      status.value = [];
      return
    }

    if (res.data && res.data.status) {
      status.value = res.data.status;
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true
  const query = getQuery()
  const contactQuery = query + '&type=employee'
  const contactRes   = fetchContactProfiles(contactQuery)
  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    getAttendancePolicy(),
    getBranchList(),
    getDepartmentList(),
    getTypes(),
    getRoleList()
  ]).finally(() => {
    loader.value = false
  })

  formData.value.effective_date =  new Date().toISOString().split('T')[0]
  if(route.query.allocationId && route.query.allocationId !== '' && route.query.allocationId !== undefined){
    isCreate.value = false;
    const fetchPolicy = fetchSingleAttendancePolicyAllocation(route.query.allocationId, getQuery());
    fetchPolicy.then(res2 => {
      attendancePolicyEditData.value = res2.data;
      setFormData();
    });
  }

}

onMounted(() => {
  onPageReload();
})
</script>
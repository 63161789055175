<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="px-2 mt-1">
      <div class="card card-height">
        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="form-label">Description</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="text"
                  placeholder="Description...."
                  v-model="formData.description"
              >
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="form-label">Effective Date</label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="date"
                  placeholder=""
                  v-model="formData.date"
              >
            </div>
            <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="align-middle">Status</label>
              <v-select
                  placeholder="Select Policy"
                  v-model="formData.status"
                  :options="status"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleContact from '@/services/modules/contact'
import {useStore} from "vuex";
import handleBranches from "@/services/modules/procurement/organization-settings/branch";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handleRole from "@/services/modules/role";


const store = useStore();
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Holiday")
let contacts = ref([])
let locations = ref([])
let accountPayable = ref([])
let holidayEditData = ref({})
const status = ref([]);

let holidayData = {}
let formData = ref({
  company_id: companyId,
  status: 'active',
  description:'',
  date:'',
})

function navigateToListPage() {
  delete route.query.holidayId;
  router.push({name: `holidays`, params: route.params, query: route.query});
}

const {
  createHoliday,
  fetchSingleHoliday,
  updateHoliday,
  fetchTypes,
} = handleHrPayroll()

function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

function handleSubmit(redirect) {
  holidayData.company_id  = formData.value.company_id;
  holidayData.status = "active";
  holidayData.description = formData.value.description;
  holidayData.date = formData.value.date;

  createAP(redirect);
}

function handleUpdate(redirect) {
  holidayData.id = formData.value.id;
  holidayData.company_id  = formData.value.company_id;
  holidayData.status = formData.value.status;
  holidayData.description = formData.value.description;
  holidayData.date = formData.value.date;

  updateAPData(redirect);
}

function createAP(redirect = true) {
  createHoliday(holidayData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message)
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function updateAPData(redirect = true) {
  updateHoliday(holidayData, holidayData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Holiday";
  formData.value.id =  holidayEditData.value.id;
  formData.value.description = holidayEditData.value.description;
  formData.value.date = holidayEditData.value.date;
  formData.value.status = holidayEditData.value.status;
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      status.value = [];
      return
    }

    if (res.data && res.data.status) {
      status.value = res.data.status;
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true

  Promise.all([
    getTypes(),

  ]).finally(() => {
    loader.value = false
  })

  formData.value.date =  new Date().toISOString().split('T')[0]
  if(route.query.holidayId && route.query.holidayId !== '' && route.query.holidayId !== undefined){
    isCreate.value = false;
    const fetchHoliday = fetchSingleHoliday(route.query.holidayId, getQuery());
    fetchHoliday.then(res2 => {
      holidayEditData.value = res2.data;
      setFormData();
    });
  }

}

onMounted(() => {
  onPageReload();
})
</script>
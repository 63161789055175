<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Stock Ledger"
      />

      <div class="row mt-2">
        <div class="col-md-4">
          <label for="colFormLabel">
              Product/Service
          </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="product"
            label="text"
            :format-label="option => option.text"
          />
        </div>
        <div class="col-md-4">
          <label for="colFormLabel">
              Inventory Location
          </label>
          <v-select
              placeholder="Select Inventory Location"
              v-model="location"
              :options="locations"
              label="text"
              :reduce="text => text.id"
          />
        </div>
      </div>
      <div class="col-12 col-lg-12 col-xl-9 col-xxl-9 mt-2">
        <DateQuerySetter
            btnTitle="Go"
            @onClickGo="onClickGo"
        />
      </div>
    </div>

    <div class="card mb-0">
      <div class="d-flex justify-content-between px-2 mb-2">
        <div>
          Product/Service Name: <strong>{{ stockLedgerHeaderData.product_name }}</strong>

          <br>
          Report Period: <strong>{{ stockLedgerHeaderData.report_period }}</strong>
        </div>

        <div>
          <table class="head">
            <tr>
              <td>Opening (Qty) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.opening_stock
                    ? stockLedgerHeaderData.opening_stock
                    : 0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>In (Qty) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.in_quantity
                    ? stockLedgerHeaderData.in_quantity
                    : 0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Out (Qty) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.out_quantity
                    ? stockLedgerHeaderData.out_quantity
                    : 0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Closing (Qty) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.closing_stock
                    ? stockLedgerHeaderData.closing_stock
                    : 0
                  }}</strong>
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="head">
            <tr>
              <td>Opening (Value) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.opening_value
                    ? commaFormat(stockLedgerHeaderData.opening_value)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>In (Value) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.in_amount
                    ? commaFormat(stockLedgerHeaderData.in_amount)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Out (Value) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.out_amount
                    ? commaFormat(stockLedgerHeaderData.out_amount)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Closing (Value) :</td>
              <td>
                <strong>{{
                    stockLedgerHeaderData.closing_value
                    ? commaFormat(stockLedgerHeaderData.closing_value)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="exportTable"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
          >
            Export
          </button>
        </div>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <StockLedgerTable
          :openingQty="stockLedgerHeaderData.opening_stock"
          :totalInQty="stockLedgerHeaderData.in_quantity"
          :totalOutQty="stockLedgerHeaderData.out_quantity"
          :closingQty="stockLedgerHeaderData.closing_stock"
          :openingValue="stockLedgerHeaderData.opening_value"
          :totalInAmount="stockLedgerHeaderData.in_amount"
          :totalOutAmount="stockLedgerHeaderData.out_amount"
          :closingValue="stockLedgerHeaderData.closing_value"
          :saleValue="stockLedgerHeaderData.sale_value"
          :profitValue="stockLedgerHeaderData.profit_value"
          :balances="stockLedgerData"
          :showOpening="page == 1"
          :showClosing="page == paginationData.last_page"
      />
    </div>
    <div class="px-2">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="reportLoading"/>
  </div>
</template>

<script>
import handleReport from '@/services/modules/inventory'
import {inject}     from 'vue'

import TitleButton               from '@/components/atom/TitleButton'
import DateQuerySetter           from '@/components/atom/DateQuerySetter'
import StockLedgerTable          from '@/components/molecule/company/stock-ledger/StockLedgerTable'
import Pagination                from "@/components/atom/Pagination";
import Loader                    from '@/components/atom/LoaderComponent'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handlePurchase            from '@/services/modules/purchase'
import figureFormatter           from "@/services/utils/figureFormatter";
import {mapMutations}            from "vuex";
import AsyncSelect               from "@/components/molecule/input-field/AsyncSelect.vue";

const {fetchBusinessLocationsList} = handleBusinessesLocations()

export default {
  name: 'StockLedger',

  components: {
    TitleButton,
    Pagination,
    Loader,
    DateQuerySetter,
    StockLedgerTable,
    AsyncSelect
  },

  data: () => ({
    product              : null,
    location             : null,
    stockLedgerData      : [],
    stockLedgerHeaderData: {},
    locations            : [],
    products             : [],
    homeContent          : {},
    paginationData       : {},
    offset               : 20,
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    },
    start() {
      return this.$route.query.start
    },
    end() {
      return this.$route.query.end
    },
    page() {
      return this.$route.query.page;
    },
    token() {
      return localStorage.getItem('token')
    }
  },

  methods: {
    ...mapMutations({
      setPaginationData: "setPaginationData",
      resetPagination: "resetPagination",
    }),

    onClickGo() {
      if (!this.product){
        this.showError('Please select a product/service');
        return;
      }

      this.setPaginationData({});
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = 1;
      this.$router.push({ path: this.$route.path, query: routeQuery });
      setTimeout(() => {
        this.getStockLedgerData(this.getQuery())
      }, 100);
    },

    exportTable() {
      let url = `${process.env.VUE_APP_BASE_URL}/export/inventory/stock-ledger?company_id=${this.companyId}&start_date=${this.start}&end_date=${this.end}&product=${this.product.id}&location=${this.location}&_token=${this.token}`
      let a   = document.createElement("a")
      a.setAttribute("href", url)
      a.click()
    },

    getQuery() {
      let query = '?company_id=' + this.companyId
      if (!this.start && !this.end) return query
      if (this.start) query += '&start_date=' + this.start
      if (this.end) query += '&end_date=' + this.end
      if (this.product) query += '&product=' + this.product.id
      query += '&location=' + this.location
      query += "&page=" + this.page
      query += "&offset=" + this.offset
      return query
    },

    async getStockLedgerData(query = '') {
      try {
        this.reportLoading = true
        let res            = await this.getStockLedgerReport(query)
        if (!res.status) {
          this.stockLedgerHeaderData = {};
          this.stockLedgerData = [];
          this.showError(res.message)
        }
        if (res.status) {
          this.stockLedgerHeaderData = res.data.headerData;
          this.stockLedgerData = res.data.stockLedgers;
          this.paginationData = res.data.pagination;
          this.setPagination();
        }
      } catch (err) {
        this.stockLedgerHeaderData = {};
        this.stockLedgerData = [];
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if (err.response?.data) {
          this.showError(err.response.data.message)
          return
        }
      } finally {
        this.reportLoading = false
      }
    },

    async getHomeContent(type) {
      try {
        this.reportLoading = true
        let res            = await this.fetchHome()
        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.homeContent = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.reportLoading = false
      }
    },

    onPageChange(page) {
      let routeQuery  = Object.assign({}, this.$route.query);
      routeQuery.page = page;
      this.$router.push({path: this.$route.path, query: routeQuery});
      setTimeout(() => {
        this.getStockLedgerData(this.getQuery())
      }, 100);
    },

    setPagination() {
      if (!this.stockLedgerData) return;
      this.setPaginationData({
        records: this.paginationData.total,
        perPage: this.paginationData.per_page,
        currentPage: this.paginationData.current_page,
      });
    },

    check() {
      this.$refs.pagination.setPage(2);
    },
  },

  setup() {
    const showError   = inject('showError');
    const showSuccess = inject('showSuccess');
    const {
      fetchHome,
      getStockLedgerReport,
      reportLoading,
    } = handleReport()
    const {fetchProductList} = handlePurchase()

    const {commaFormat} = figureFormatter();

    return {
      getStockLedgerReport,
      reportLoading,
      showError,
      showSuccess,
      fetchHome,
      commaFormat,
      fetchProductList
    }
  },

  mounted() {
    this.resetPagination();
    const companyQuery        = `?company_id=${this.companyId}`
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    Promise.all([
      businessLocationRes.then(res => {
        if (res.data) {
          this.locations = res.data;
          this.locations.push({
            id  : 'all',
            text: 'All'
          });
        }
      })
    ])
    .then(() => {
      this.location = 'all';
      this.reportLoading = false
    })
    .catch((err) => {
      this.reportLoading = false
    })

    this.getHomeContent();
  }
}
</script>

<style scoped>
.head td {
  text-align: right;
}
</style>

import Network from '@/services/network'
import { ref } from 'vue'

export default function handleInventory() {
    const network = new Network;
    const chartLoading = ref(false)
    const chartCreateLoading = ref(false)
    const reportLoading = ref(false)
    //-------------------------------------------------------------------------------

    const fetchHome = () => { //status, voucher, account_type
      return network.api('get', '/get/home');
    }
    const fetchClue = (companyId) => {
        return network.api('get', '/inventory/specifications?company_id='+companyId);
    }

    const fetchAccountHeads = (companyId) => {
      return network.api('get', '/account-heads?company_id='+companyId);
    }

    const fetchUnit = (companyId) => {
      return network.api('get', '/inventory/units?company_id='+companyId);
    }

    const fetchGroup = (companyId,productType) => {
      return network.api('get', 'inventory/products/groups?company_id='+companyId+'&is_flatten=1&product_type='+productType);
    }

    const storeSpecification = (data) => {
      return network.api('post', '/inventory/specifications', data);
    }

    const updateSpecification = (data) => {
      return network.api('post', '/inventory/specifications/'+data.id, data);
    }

    const fetchSpecification = (companyId) => {
      return network.api('get', '/inventory/specifications?company_id='+companyId);
    }

    const fetchProductList = (query) => {
      return network.api('get', '/inventory/products'+query);
    }

    const fetchSpecificationValue = (name, companyId) => {
      return network.api('get', `/inventory/specifications/${name}/values?company_id=`+companyId);
    }

    const fetchCountryOfOrigins = (companyId) => {
      return network.api('get', '/inventory/country-of-origin'+companyId);
    }

    const storeProduct = (data) => {
      return network.api('post', '/inventory/products', data);
    }

    const storeGroup = (data) => {
      return network.api('post', '/inventory/products/groups', data);
    }

    const updateGroup = (data) => {
      return network.api('post', '/inventory/products/groups/'+data.id, data);
    }

    const updateProduct = (data, productId) => {
      return network.api('post', '/inventory/products/'+productId, data);
    }

    const getProduct = (data) => {
      return network.api('get', '/inventory/products/'+data.id+'?company_id='+data.company_id);
    }

    const fetchLocationList = (query) => {
        return network.api('get', '/inventory/business-locations' + query);
    }

    const fetchBusinessLocationGroup = (companyId, locationType) => {
        return network.api('get', 'inventory/business-locations/location-groups?company_id='
                                  + companyId
                                  + '&is_flatten=1&location_type='
                                  + locationType);
    }

    const storeBusinessLocationGroup = (data) => {
        return network.api('post', '/inventory/business-locations/location-groups', data);
    }

    const updateBusinessLocationGroup = (data) => {
        return network.api('post', '/inventory/business-locations/location-groups/' + data.id, data);
    }

    const storeBusinessLocation = (data) => {
        return network.api('post', '/inventory/business-locations', data);
    }

    const updateBusinessLocation = (data) => {
        return network.api('post', '/inventory/business-locations/update-location', data);
    }

    const getStockRegisterReport = (query) => {
        return network.api('get', '/inventory/stock-register'+ query);
    }

    const getStockRegisterDetailedReport = (query) => {
        return network.api('get', '/inventory/stock-register/detailed-report'+ query);
    }

    const getStockRegisterOldReport = (query) => {
        return network.api('get', '/inventory/stock-register-old'+ query);
    }

    const getStockLedgerReport = (query) => {
        return network.api('get', '/inventory/stock-ledger'+ query);
    }

    const getStockAdjustmentReport = (query) => {
        return network.api('get', '/inventory/stock-adjustment'+ query);
    }

    const fetchGRNList = (query) => {
        return network.api('get', '/inventory/grn'+query);
    }
    const fetchGrnNumber = query => {
        return network.api('get', '/inventory/grn/generate-grn-number' + query);
    }
    const createNewGRN = (data, query) => {
        return network.api('post', '/inventory/grn'+query, data);
    }
    const updateGRN = (data, grnId, query) => {
        return network.api('put', '/inventory/grn/'+ grnId + query, data);
    }
    const fetchSingleGRN = (grnId, query) => {
        return network.api('get', '/inventory/grn/' + grnId + query);
    }
    const fetchSingleRNGeneral = (grnGeneralId, query) => {
        return network.api('get', '/inventory/grn-general/' + grnGeneralId + query);
    }
    const fetchAllPOBySupplierId = (supplierId, query) => {
        return network.api('get', '/inventory/grn/get-po-by-supplier/' + supplierId + query);
    }

    const fetchAlternativeUnits = (query) => {
        return network.api('get', '/inventory/alternative/units' + query);
    }

    const addAlternativeUnits = (data) => {
        return network.api('post', '/inventory/alternative/units', data);
    }

    const getUnitById = (id) => {
        return network.api('get', `/inventory/alternative/units/${id}`);
    }

    const updateAlternativeUnits = (id, data) => {
        return network.api('put', `/inventory/alternative/units/${id}`, data);
    }

    const fetchProductAlternativeUnits = (productId, query) => {
        return network.api('get', `/inventory/alternative/units/${productId}${query}`);
    }

    const uploadCsvFile = (data) => {
        return network.api('post', '/inventory/products/upload-csv', data)
    }

    const fetchCsvData = (query) => {
      return network.api('get', `/inventory/products/csv-data${query}`)
    }

    const productUpload = (query) => {
      return network.api('post', `/inventory/products/final-product-upload${query}`)
    }

    const fetchCsvRow = (query) => {
      return network.api('get', `/inventory/products/get-each-row${query}`)
    }

    const deleteCsvRow = (query, id) => {
      return network.api('post', `/inventory/products/remove-batch-row/${id}` + query)
    }

    const fetchGroupParent = (query) => {
      return network.api('get', `v2/inventory/products/group${query}`)
    }

    const fetchGroupByParent = (id, query) => {
      return network.api('get', `v2/inventory/products/${id}${query}`)
    }

    const fetchGroupProductList = (query) => {
      return network.api('get', `v2/inventory/products/list/group${query}`)
    }

    const fetchEndpointProductList = (query) => {
      return network.api('get', `v2/inventory/products/list/endpoint${query}`)
    }

    const fetchEndpointProductDetails = (query) => {
      return network.api('get', `v2/inventory/products/list/endpoint/details${query}`)
    }

    return {
        fetchHome,
        fetchClue,
        storeSpecification,
        fetchAccountHeads,
        fetchSpecification,
        fetchSpecificationValue,
        fetchUnit,
        fetchGroup,
        storeProduct,
        storeGroup,
        fetchProductList,
        updateGroup,
        updateProduct,
        getProduct,
        updateSpecification,

        fetchCountryOfOrigins,

        fetchLocationList,
        fetchBusinessLocationGroup,
        storeBusinessLocationGroup,
        updateBusinessLocationGroup,
        storeBusinessLocation,
        updateBusinessLocation,

        getStockRegisterOldReport,
        getStockRegisterReport,
        getStockRegisterDetailedReport,
        getStockLedgerReport,
        getStockAdjustmentReport,

        chartCreateLoading,
        chartLoading,
        reportLoading,

        fetchGRNList,
        fetchGrnNumber,
        createNewGRN,
        updateGRN,
        fetchSingleGRN,
        fetchSingleRNGeneral,
        fetchAllPOBySupplierId,

        //Alternative Units
        getUnitById,
        addAlternativeUnits,
        fetchAlternativeUnits,
        updateAlternativeUnits,
        fetchProductAlternativeUnits,

        //CSV
        uploadCsvFile,
        fetchCsvData,
        productUpload,
        fetchCsvRow,
        deleteCsvRow,

        //v2
        fetchGroupParent,
        fetchGroupByParent,
        fetchGroupProductList,
        fetchEndpointProductList,
        fetchEndpointProductDetails
    }
}

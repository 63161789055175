<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Stock Register"
      />

      <div class="row mt-2">
        <div class="col-md-4">
          <label for="colFormLabel">
              Product/Service Type
          </label>
          <v-select
              placeholder="Select Product/Service Type"
              v-model="productType"
              :options="productTypes"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-md-4">
          <label for="colFormLabel">
              Product/Service
          </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="product"
            label="text"
            :format-label="option => option.text"
            :additional-option="additionalProductOption"
          />
        </div>
        <div class="col-md-4">
          <label for="colFormLabel">
              Inventory Location
          </label>
          <v-select
              placeholder="Select Inventory Location"
              v-model="location"
              :options="locations"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-md-4">
            <label for="colFormLabel">
              Supplier
            </label>
            <AsyncSelect
                placeholder="Supplier Name, ID, Mobile No"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :additional-query="{'type' : 'supplier'}"
                :format-label="formatPatientLabel"
            />
        </div>
      </div>
      <div class="col-12 col-lg-10 col-xl-10 col-xxl-10 mt-2">
        <DateQuerySetter
            btnTitle="Go"
            @onClickGo="onClickGo"
        />
      </div>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <StockRegisterTable :balances="stockRegisterData" :reportTotalRow="reportTotalRow"/>
    </div>

    <div class="p-2">
      <Pagination
        :offset="offset"
        @onPageChange="onPageChange"
        ref="pagination"
      />
    </div>

    <Loader v-if="reportLoading"/>
  </div>
</template>

<script>
import handleReport from '@/services/modules/inventory'
import { inject } from 'vue'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import StockRegisterTable from '@/components/molecule/company/stock-register/StockRegisterTable'
import Loader from '@/components/atom/LoaderComponent'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handlePurchase from '@/services/modules/purchase'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import Pagination from "@/components/atom/Pagination.vue";
import {useStore} from "vuex";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleContact from '@/services/modules/contact'

const {fetchBusinessLocationsList} = handleBusinessesLocations()

export default {
  name: 'StockRegister',

  components: {
    TitleButton,
    Loader,
    DateQuerySetter,
    StockRegisterTable,
    AsyncSelect,
    Pagination
  },

  data: () => ({
    productType: '',
    product: '',
    location: null,
    stockRegisterData: [],
    reportTotalRow: [],
    locations: [],
    products: [],
    productTypes: [],
    homeContent: {},
    contactProfile: null,
    offset: 100,
    additionalProductOption: [{
      id: '',
      text: 'All'
    }],
    page: 1
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    },
    start() {
      return this.$route.query.start
    },
    end() {
      return this.$route.query.end
    },
    token () {
      return localStorage.getItem('token')
    }
  },

  methods: {
    onClickGo() {
      this.getStockRegisterBalance(this.getQuery())
    },

    exportTable () {
      const queryObject = {
        company_id: this.companyId,
        start_date: this.start,
        end_date: this.end,
        product_type: this.productType,
        product: this.product ? this.product.id : '',
        location: this.location,
        offset: this.offset,
        page: this.page,
        _token: this.token
      }

      const queryString = new URLSearchParams(queryObject).toString();

      let url = `${process.env.VUE_APP_BASE_URL}/export/inventory/stock-register?${queryString}`
      let a = document.createElement("a")
      a.setAttribute("href", url)
      a.click()
    },

    getQuery (onMounted = false, page = 1) {
      const queryObject = {
        company_id: this.companyId,
        start_date: this.start,
        end_date: this.end,
        product_type: this.productType,
        product: this.product ? this.product.id : '',
        location: onMounted ? '' : this.location,
        offset: this.offset,
        contact_profile_id: this.contactProfile ? this.contactProfile.id : '',
        page
      }

      return '?' + new URLSearchParams(queryObject).toString();
    },

    async getStockRegisterBalance(query = '') {
      try {
        this.reportLoading = true
        let res = await this.getStockRegisterReport(query)
        if(!res.status) {
          this.stockRegisterData = [];
          this.showError(res.message)
        }
        if(res.status) {
          this.stockRegisterData = res.data.data
          this.reportTotalRow = res.data.report_total_row
          this.setPagination(res.data)
        }
      } catch (err) {
        this.stockRegisterData = [];
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response?.data) {
          this.showError(err.response.data.message)
        }
      } finally {
        this.reportLoading = false
      }
    },

    makeProductTypesDropdownOptions() {
      this.productTypes.push({
        id : '',
        text : 'All'
      });

      for (let key in this.homeContent.product_type) {
        this.productTypes.push({
          id : key,
          text : this.homeContent.product_type[key]
        });
      }
    },

    async onPageChange(page){
      this.page = page;
      let routeQuery = Object.assign({}, this.$route.query)
      routeQuery.page = page;

      await this.$router.push({path: this.$route.path, query: routeQuery})
      await this.getStockRegisterBalance(this.getQuery(false, page))
    }
  },

  setup() {
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    const $store = useStore();
    const { fetchContactProfiles } = handleContact();
    const { formatPatientLabel } = useAsyncDropdownHelper();
    const { fetchProductList } = handlePurchase()
    const { fetchHome,
            getStockRegisterReport,
            reportLoading,
          } = handleReport()

    const setPagination = (data) => {
      setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
      })
    }

    const setPaginationData = (data) => {
      $store.commit('setPaginationData', data)
    };

    return {
      getStockRegisterReport,
      reportLoading,
      showError,
      showSuccess,
      fetchHome,
      fetchProductList,
      setPagination,
      fetchContactProfiles,
      formatPatientLabel
    }
  },

  mounted () {
    this.reportLoading = true;
    const companyQuery = `?company_id=${this.companyId}`
    Promise.all([
      this.fetchHome().then(res => {
        this.homeContent = res.data;
        this.makeProductTypesDropdownOptions();
      }),
      fetchBusinessLocationsList(companyQuery).then(res=> {
        if(res.data){
          this.locations = [
            {
              id : '',
              text : 'All'
            },
            ...res.data];
        }
      })
    ])
    .then(() => {
      this.productType = 'finished_goods'
      this.location = ''
      this.getStockRegisterBalance(this.getQuery())
    })
    .catch(()=>{})
  }
}
</script>

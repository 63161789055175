<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div  class="card mb-1">

      <DateQuerySetter :inEnoughSpace="false" btnTitle="Go" @onClickGo="investigationInvoiceList">

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">
            Product/Service Type
          </label>
          <v-select
            placeholder="Select Product/Service Type"
            v-model="productType"
            :options="productTypes"
            label="text"
            :reduce="text => text.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Product Group </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="ancestorId"
            label="text"
            :reduce="text => text.id"
            :additional-query="groupQueryObj"
            :format-label="option => option.text"
            ref="groupSelector"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Product/Service </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="productId"
            label="text"
            :reduce="text => text.id"
            :additional-query="productQueryObj"
            :format-label="option => option.text"
            ref="productSelector"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Referral </label>
          <AsyncSelect
              placeholder="Select Referrer"
              v-model="singleRe"
              :api-service="fetchContactProfiles"
              :additional-query="{ type: 'agent' }"
              :format-label="formatPatientLabel"
              :reduce="referrer => referrer.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Bill type: </label>
          <v-select
              multiple
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Party: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
          </div>

          <AsyncSelect
              placeholder="Select Party"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
              :format-label="formatPatientLabel"
              :key="contactRenderKey"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="onClickToPrint"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect mx-1"
        >
          Print
        </button>
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <ListTable 
          :tableItems="investigations"
          :currentPage="itemData && itemData.data && itemData.data.current_page"
          :lastPage="itemData && itemData.data &&  itemData.data.last_page"
          :totalValue="itemData && itemData.total"
      />
    </div>

    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <ContactDropdownFilter
        v-if="$store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="customer"
    />
    <Loader v-if="reportLoading"/>
  </div>
</template>

<script setup>
import {onMounted, ref, defineEmits, computed, watch, inject, reactive} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleReport from '@/services/modules/inventory';
import handlePurchase from '@/services/modules/purchase';
import handleHospitalReport from "@/services/modules/hospital/report";
import handleContact from "@/services/modules/contact";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleCrm from '@/services/modules/crm/crm.js'
import {useRoute, useRouter} from "vue-router";
import ListTable from "@/components/molecule/company/inventory/product-sales-register/ItemTabTable";
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";
import Loader from "@/components/atom/LoaderComponent.vue";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import itemPrinter from '@/services/utils/pdf/saleRegisterItem';
import handleCompany from "@/services/modules/company";

const {fetchHome, reportLoading} = handleReport();
const {fetchInvestigationInvoiceReport} = handleHospitalReport();
const {fetchProductList} = handlePurchase();
const {fetchContactProfiles} = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchAllSR } = handleCrm();
const { exportToPDF } = itemPrinter();
const { fetchCompanyInfo } = handleCompany();

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const $router = useRouter()
const $route = useRoute()
const $store = useStore()

const productId = ref(null);
const ancestorId = ref(null);
const productType = ref(null);
const productSelector = ref(null);
const groupSelector = ref(null);
const productTypes = ref([]);
const billTypeList = ref([])
const billType = ref(null)
let contactProfile = ref(null)
const contactRenderKey = ref(0);
const itemData = ref( {});
const saleItemData = ref( {});
const singleRe = ref(null);
let companyInfo = reactive({})

const investigations = ref([]);
const offset = 20;

const companyId = computed(() => $route.params.companyId);
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const page = computed(() => $route.query.page);
const token = computed(() => localStorage.getItem('token'));

const additionalContactOption = ref({
  with_mobile: 1,
  dealer_id: '',
  roles: 'customer'
});

const productQueryObj = computed(() => {
  const queryObj = {};

  if (!!productType.value && productType.value !== '') {
    queryObj.product_type = productType.value;
  }

  if (!!ancestorId.value) {
    queryObj.ancestor_id = ancestorId.value;
  }

  return queryObj;

})
const groupQueryObj = computed(() => {
  const queryObj = {
    group_level: 1
  };

  if (!!productType.value) {
    queryObj.product_type = productType.value;
  }

  return queryObj;

})

const exportTable = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/export/reports/investigation-invoice-report${query}&_token=${token.value}&is_export=1&is_item=1`;
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.click();
};

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  if (!!billType.value && billType.value.length > 0) {
    query += '&sale_type=' + billType.value
  }
  if(contactProfile.value) query += '&contact_profile_id='+contactProfile.value.id
  if (!!productId.value) query += '&product_id=' + productId.value
  if (!!productType.value) query += '&product_type=' + productType.value
  if (!!ancestorId.value) query += '&ancestor_id=' + ancestorId.value
  if(singleRe.value) query += `&referral_id=${singleRe.value}`;
  if (!start.value && !end.value) return query
  if (start.value) query += '&start_date=' + start.value
  if (end.value) query += '&end_date=' + end.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset
  return query
};

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  await $router.push({path: $route.path, query: routeQuery})

  await investigationInvoiceList();
}

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
};

const onOpenContactSearchModal = () => {
  $store.state.isModalOpenThree = true;
}
const setPaginationData = (data) => {
  $store.commit('setPaginationData', data)
};

const resetPagination = () => {
  $store.commit('resetPagination')
};

watch(ancestorId, () => {
  productSelector.value.clear();
  productId.value = null;
})

watch(productType, () => {
  productSelector.value.clear();
  groupSelector.value.clear();
  productId.value = null;
  ancestorId.value = null;
})

async function onClickToPrint() {
  let query = getQuery();
  query += '&is_all=true'
  let startDate = $route.query.start ? $route.query.start : '';
  let endDate = $route.query.end ? $route.query.end : '';

  await fetchInvestigationInvoiceReport(query).then((res) => {
    if (res.status) {
      saleItemData.value = res
    }
  }).then(() => {
        exportToPDF(companyInfo,saleItemData.value,true,false,startDate,endDate)
   }).catch((err) => {
      console.log(err)
      console.log("Something went wrong");
   });
}

async function investigationInvoiceList() {
  reportLoading.value = true;
  try {
    const res = await fetchInvestigationInvoiceReport(getQuery());
    if (!res.status) {
      investigations.value = [];
      itemData.value = {} 
      return resetPagination()
    }
    const {data, ...paginationData} = res.data;
    investigations.value = data;
    itemData.value = res 
    await setPagination(paginationData)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    reportLoading.value = false;
  }
}
const setCompanyInfo = async () => {
  await fetchCompanyInfo(companyId.value).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

onMounted(async () => {
  reportLoading.value = true;
  try {
    const [ homeRes ] = await Promise.all([
      fetchHome()
    ]);
    if (homeRes.data && homeRes.data.product_type) {
      for (let key in homeRes.data.product_type) {
        productTypes.value.push({
          id : key,
          text : homeRes.data.product_type[key]
        });
      }
    }
    if (homeRes.data && homeRes.data.hospital_invoice_types) {
      for (const value of Object.keys(homeRes.data.hospital_invoice_types)) {
        billTypeList.value.push({
          value: value,
          label: homeRes.data.hospital_invoice_types[value]
        })
      }
    }
    location.value = 'all';
  } finally {
    reportLoading.value = false;
  }
  await investigationInvoiceList()
  await setCompanyInfo()
});
</script>


<style scoped>
.head td {
  text-align: right;
}
</style>
